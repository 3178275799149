import React from "react";
import { Link } from "react-router-dom";
import "./Bowlers.css";
import { ScoreboardPlayerImage } from "../PlayerImage";
const Bowler = ({ bowlers, currentMatch }) => {
  return (
    <div className="details-right overflow-scroll">
      <table>
        <tbody className="tbodybatter-bowler" id="tblBowlerScorecardlistbody">
          <tr>
            <td className="player align-left">Bowler</td>
            <td className="center-align">O</td>
            <td className="center-align">M</td>
            <td className="center-align">R</td>
            <td className="center-align">W</td>
            <td className="center-align">ECO</td>
          </tr>
          {bowlers.map((bowler, index) => {
            return (
              <tr key={index} className="player-details">
                <td className="player">
                  <div className="d-flex align-items-center">
                    <ScoreboardPlayerImage
                      width="22px"
                      playerImage={bowler?.bli}
                      jerseyImage={
                        currentMatch?.scot ===
                          currentMatch?.t2sn
                          ? currentMatch?.t1jr
                          : currentMatch?.t2jr
                      }
                    />
                    {!bowler?.isp ? bowler?.pn : "***"}
                  </div>
                </td>
                <td className="center-align">{parseFloat(bowler?.tov)}</td>
                <td className="center-align">{bowler?.mov}</td>
                <td className="center-align">{bowler?.trun}</td>
                <td className="center-align">{bowler?.twik}</td>
                <td className="center-align">{bowler?.eco}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div id="popup" className="left scoreLinkDiv">
        <Link
          to={"/#"}
          target="_blank"
          rel="noopener noreferrer"
          className="scoreLInk"
        >
          Score by:betskey.com
        </Link>
      </div>
    </div>
  );
};

export default Bowler;
