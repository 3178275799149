import PlayerImage from "./PlayerImage";
import PartnershipDetailsSkeleton from "./Skeleton/PartnershipDetailsSkeleton";

const PartnershipDetails = ({ partnershipData, loading }) => {
    return loading ? (
         <PartnershipDetailsSkeleton />
        ) : (
            partnershipData?.td.length > 0 ? ( 
        <div className="accordion" id="accordion">
        {partnershipData?.td && partnershipData.td?.map((currentTeam, index) => {
            return (
                <div className="accordion-item">
                    <h5 className="" data-toggle="collapse" data-target={`#team-${index}`} aria-expanded="true">
                        <div className="flag-avatar">
                            <figure>
                                <img
                                    src={currentTeam?.image || "/assets/images/flag.png"}
                                    alt="Team 2 Image"
                                />
                            </figure>
                        </div>
                        {currentTeam?.teamName}

                    </h5>
                    <div id={`team-${index}`} className="collapse show" >
                        {partnershipData?.par?.filter(currentPlayer => (currentPlayer?.teamId === currentTeam?.teamId) && (currentPlayer?.currentInnings === currentTeam?.currentInnings) && (currentPlayer.batter1Name || currentPlayer.batter2Name))?.map((currentPlayer) => {
                            return (
                                <div className="card card-shadow p-0">
                                    <div className="score-card score-card-lg d-md-flex p-0">
                                        <div className="score-card-inner flex-grow-1 px-20 py-20">
                                            <div className="score-card-body">
                                                <div className="country-info cards-divider">
                                                    <div className="flag-avatar">
                                                        <PlayerImage
                                                            width="40px"
                                                            playerImage={currentPlayer?.player1image}
                                                            jerseyImage={currentTeam?.jersey}
                                                        />
                                                        <span className="country-name">{currentPlayer.batter1Name}</span>
                                                    </div>
                                                </div>
                                                <h2>{currentPlayer.totalRuns}({currentPlayer.totalBalls})</h2>
                                                <div className="country-info flex-row-reverse cards-divider">
                                                    <div className="flag-avatar ml-10">
                                                        <PlayerImage
                                                            width="40px"
                                                            playerImage={currentPlayer?.player2image}
                                                            jerseyImage={currentTeam?.jersey}
                                                        />
                                                        <span className="country-name">{currentPlayer.batter2Name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        })}

    </div> 
    ) : (
        <div className="card card-shadow p-0">
          <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
            <strong> Match has not started yet </strong>
          </div>
        </div>
    )
    )

}

export default PartnershipDetails;