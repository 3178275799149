import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../Features/axios";
import { convertDateTimeUTCToLocal } from "../utilities/functions";
import BannerDetailsSkeleton from "./Skeleton/BannerDetailsSkeleton";

const BannerDetailsSection = () => {
  const { id } = useParams();
  const [bannerDetails, setBannerDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBannerContent = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/banner/getBannerbyId`,
          { bannerId: +id }
        );
        const bannerContent = response?.data;
        setBannerDetails(bannerContent);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerContent();
  }, [id]);

  return (
    <>
      <div class="col-lg-9">
        {loading ? (
          <BannerDetailsSkeleton />
        ) : (
          bannerDetails && (
            <div class="card card-shadow p-0" key={bannerDetails.bannerId}>
              <div class="score-card score-card-lg p-0">
                <div class="score-card-inner px-20 py-20">
                  <div class="score-card-header mb-15">
                    <strong>{bannerDetails?.title}</strong>
                    <figure>
                      <img
                        src={bannerDetails?.image || "/assets/images/flag.png"}
                        style={{ height: "500px", width: "100%" }}
                        alt=""
                      />
                    </figure>
                    <span>
                      {
                        convertDateTimeUTCToLocal(bannerDetails?.startDate)
                          .localDate
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default BannerDetailsSection;
