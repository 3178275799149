import React from "react";
import Skeleton from "react-loading-skeleton";

const DynamicRoutesSkeleton = () => {
  return (
    <div class="col-lg-9">
      <section class="live-matches pt-0">
        <div class="card card-shadow">
          <Skeleton />
          <Skeleton height={100} />
        </div>
      </section>
    </div>
  );
};

export default DynamicRoutesSkeleton;
