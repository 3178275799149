// import ReachUs from "./ReachUs";
import BannerContent from "./BannerContent";
import NewsSidebarContent from "./NewsSidebarContent";
import Standings from "./Standings";
import UpCommingMatch from "./UpCommingMatch";

const MatchSideBar = () => {
  return (
    <div class="col-lg-3">
      <aside class="sidebar right-sidebar">
        <BannerContent />
        <NewsSidebarContent />
        {/* <UpCommingMatch />
        <Standings /> */}
        {/* <ReachUs /> */}
      </aside>
    </div>
  )
}

export default MatchSideBar;