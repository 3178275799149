import moment from "moment-timezone";

export const ConverTime = (time) => {
  const timeString = time;
  const [hours, minutes] = timeString.split(":").map(Number);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTimeString = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedTimeString; // Output: "04:00 AM"
};

export const convertToUTC = (dateString, timeString) => {
  const [day, month, year] = dateString.split("/");
  const [hours, minutes, seconds] = timeString.split(":");

  // Construct the date and time in ISO 8601 format
  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  // Create a Date object in local time
  const localDate = new Date(isoString);

  // Get the UTC date and time string
  const utcDateTimeString = localDate
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");
  return utcDateTimeString;
}
export const sortMatchesByDateTime = (matches, ascending = true)=> {
  matches.sort((a, b) => {
    const dateA = moment(a.utc);
    const dateB = moment(b.utc);
    if (dateA < dateB) return ascending ? -1 : 1;
    if (dateA > dateB) return ascending ? 1 : -1;
    return 0;
  });

  return matches;
}

export const convertDateUTCToLocal = (UTCDate, page, format) => {
  if (UTCDate) {
    if (page === 'index') {
      return moment(UTCDate).local().format("DD/MM/YY, h:mm:ss a");
    }
    if (format) return moment(UTCDate).local().format(format);
    return moment(UTCDate).local().format("YYYY-MM-DDTHH:mm:ss");
  }
  return "";
}
