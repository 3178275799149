import { isArray } from "lodash";
import React from "react";

const CurrentOver = ({ currentMatch, isMobile = false }) => {
  return (
    <div className={`current-over current-over-ext`}>
      <div className={`balls over-container-2 ${isMobile ? "" : "desktop-overs"}`}>
        {Object.keys(currentMatch?.currOver || {}).map((over, overIndex) => {
          return isArray(currentMatch?.currOver[over]) &&
            <div >
              <ul className=" ball-container over-container d-flex">
                {currentMatch?.currOver[over]?.map((value, index) => (
                  <li
                    key={overIndex + index}
                    style={{ opacity: value.opacity }}
                    className={`ball ${value.ballType} new-ball `}
                  >
                    {value.run}
                  </li>
                ))}
              </ul>
            </div>
        })}
      </div >
    </div >
  );
};

export default CurrentOver;
