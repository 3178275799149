import React, { useEffect, useState } from "react";
import RenderStatus from "./RenderStatus";
import _, { isEqual } from "lodash";

const ScoreHeader = ({ currentMatch, styleRef, battingTeam, bowlingTeam, percentageLeft, percentageRight}) => {
  const [teamOne, setTeamOne] = useState("");
  const [teamTwo, setTeamTwo] = useState("");
  const [currentOver, setCurrentOver] = useState([]);
  const [currentStyle, setCurrentStyle] = useState(null);

  const { backgroundColor, color } = styleRef?.current;
  const customStyles = {
    backgroundColor: backgroundColor !== "#null" ? backgroundColor : "#000",
    color: color !== "#null" ? color : "#fff",
  };

  useEffect(() => {
    setTeamOne(
      currentMatch?.t1im ||
      (currentMatch?.scot === currentMatch?.t1sn
        ? "images/batting.png"
        : "images/bowling.png")
    );
    setTeamTwo(
      currentMatch?.t2im ||
      (currentMatch?.scot === currentMatch?.t2sn
        ? "images/batting.png"
        : "images/bowling.png")
    );
  }, [currentMatch?.scot]);

  const updateStyleWithTimeout = (newStyle) => {
    setCurrentStyle(newStyle);
    setTimeout(() => {
      setCurrentStyle(null);
    }, 4500);
  };

  // const generateActionAnimation = (currentBall, currentMatch) => {
  //   const cutomStyle = {};
  //   if (currentBall.isb) {
  //     cutomStyle["bgColor"] = currentMatch?.t1bg;
  //     cutomStyle["borderColor"] = currentMatch?.t1co;
  //     cutomStyle["text"] = +currentBall?.run === 4 ? "Four" : "Six";
  //     cutomStyle["image"] =
  //       +currentBall?.run === 4 ? "/images/four.png" : "/images/six.png";
  //   } else {
  //     cutomStyle["bgColor"] = currentMatch?.t2bg;
  //     cutomStyle["borderColor"] = currentMatch?.t2co;
  //     cutomStyle["text"] = "Wicket!";
  //     cutomStyle["image"] = "/images/out.png";
  //   }
  //   updateStyleWithTimeout(cutomStyle);
  // };

  const generateActionAnimation = (currentBall, currentMatch) => {
    let cutomStyle = {};
    if (currentBall?.isb) {
      cutomStyle = {
        text: +currentBall?.actualRun === 4 ? "Four" : +currentBall?.actualRun === 6 ? "Six" : "",
        image: +currentBall?.actualRun === 4 ? "/images/four.png" : +currentBall?.actualRun === 6 ? "/images/six.png" : "",
        ...battingTeam
      }
    } else {
      cutomStyle = {
        text: "Wicket!",
        image: "/images/out.png",
        ...bowlingTeam
      }
    }
    updateStyleWithTimeout(cutomStyle);
  };

  useEffect(() => {
    const currentBall = currentMatch?.currOver?.["currentBall"] || {}
    const currOverFromCurrMatch = Object.values(currentMatch.currOver || {}).pop() || []
    if (
      !isEqual(currentOver, currOverFromCurrMatch) && (currentBall.isb || currentBall.isw)
    ) {
      setCurrentOver(currOverFromCurrMatch);
      // if (currentOver?.length <= currentMatch?.currOver?.length)
      generateActionAnimation(currentBall, currentMatch);
    }
  }, [currentMatch]);

  return (
    <>
      <div className="dynamic-score backgound-banner">
        {currentStyle && (
          <div
            style={{
              color: `${currentStyle?.borderColor || "white"}`,
            }}
            className="foreground-text1"
          >
            {currentStyle.text}
          </div>
        )}
        {currentStyle && (
          <img
            className="umpire-image1"
            src={currentStyle?.image}
            alt="Umpire"
          ></img>
        )}
        <div
          className="top-header"
          style={
            currentStyle
              ? {
                border: `5px solid ${currentStyle?.borderColor}`,
                backgroundColor: `${currentStyle?.bgColor}`,
              }
              : {
                ...customStyles,
              }
          }
        // style={{...customStyles}}
        > 
          <div className="top-header-1 valign-wrapper">
          <div className="left-team">
            <div className="team-name">
              <span className="team-img">
                <img
                  src={teamOne}
                  alt="batting"
                  className="left-icon team1Side"
                />
              </span>
              <div className="left-score">
                <span className="team-title team1Name">
                  {currentMatch?.t1sn}{" "}
                </span>
                <div className="team-score">
                  <span className=" team-score team1Score">
                    {currentMatch?.t1md?.runs}-{currentMatch?.t1md?.wickets}
                  </span>
                  <div className="overs text-left">
                    <span className="team1Over">
                      {currentMatch?.t1md?.overs} ov
                    </span>
                    {currentMatch.t1md?.previousInnings.map((value, key) => (
                      <span className="team1RefScore" key={key}>
                        &amp; {value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator">
            <div className="dynamic-score backgound-banner">
              {currentStyle && (
                <div
                  style={{
                    color: `${currentStyle?.borderColor || "white"}`,
                  }}
                  className="foreground-text1"
                >
                  {currentStyle.text}
                </div>
              )}
              <div
                className="center-content"
                style={{ display: currentStyle && "none" }}
              // style={
              //   currentStyle
              //     && {
              //         border: `5px solid ${currentStyle?.borderColor}`,
              //         backgroundColor: `${currentStyle?.bgColor}`,
              //       }
              // }
              >
                {!currentStyle && <RenderStatus display={currentMatch?.dis || currentMatch?.win} />}
              </div>
            </div>
          </div>
          <div className="right-team">
            <div className="team-name">
              <div className="right-score">
                <div className="team-score">
                  <span className="team-score team2Score">
                    {currentMatch.t2md?.runs}-{currentMatch.t2md?.wickets}
                  </span>
                  <div className="overs text-left">
                    <span className="team2Over">
                      {currentMatch.t2md?.overs} ov
                    </span>
                    {currentMatch.t2md?.previousInnings.map((value, index) => (
                      <span key={index} className="team2RefScore">
                        &amp; {value}
                      </span>
                    ))}
                  </div>
                </div>
                <span className="team-title team2Name">{currentMatch.t2sn}</span>
              </div>

              <span className="team-img">
                <img
                  src={teamTwo}
                  alt="bowling"
                  className="right-icon team2Side"
                />
              </span>
            </div>
          </div>
          </div>
          {percentageLeft && percentageRight ? (
          <div className="progress-bars-1 w-100 mt-1">
            <div className="progress-title d-flex flex-column align-items-center">
              <div className="progress">
                <div
                  className="d-flex"
                  style={{
                    width: `${percentageLeft}%`,
                    backgroundColor: percentageLeft > percentageRight ? "green" : "red",
                  }}
                ></div>
                <div
                  className="d-flex"
                  style={{
                    width: `${percentageRight}%`,
                    backgroundColor: percentageRight > percentageLeft ? "green" : "red",
                  }}
                ></div>
              </div>
              <div className="d-flex justify-content-between progress-content">
                <span className="progress-amount">({percentageLeft}%)</span>
                <span className="progress-amount">Realtime Win %</span>
                <span className="progress-amount">({percentageRight}%)</span>
              </div>
            </div>
          </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ScoreHeader;
