import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { convertDateTimeUTCToLocal } from "../utilities/functions";
import { Link, useNavigate } from "react-router-dom";
import NewsContentSkeleton from "./Skeleton/NewsContentSkeleton";

const NewsContent = () => {
  const [newsData, setNewsData] = useState([]);
  // const [ipAddress, setIpAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchIpAddress = async () => {
  //     try {
  //       const response = await fetch("https://api.ipify.org/?format=json");
  //       const data = await response.json();
  //       setIpAddress(data.ip);
  //     } catch (error) {
  //       console.error("Error fetching IP address:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchIpAddress();
  // }, []);

  useEffect(() => {
    const fetchNewsContent = async () => {
      try {
        const response = await axiosInstance.post(`/admin/news/getNews`);
        const newsContent = response?.data;
        setNewsData(newsContent);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsContent();
  }, []);

  const activityViewerFunc = async (newsId, encodedTitle) => {
    try {
      const viewedNews = JSON.parse(localStorage.getItem("viewedNews")) || [];

      if (!viewedNews.includes(newsId)) {
        const payload = { refId: newsId };
        const response = await axiosInstance.post(
          `/admin/newsViewers/save`,
          payload
        );
        console.log("response", response?.data);
        // navigate(`/news/${newsId}/${encodedTitle}`);

        localStorage.setItem(
          "viewedNews",
          JSON.stringify([...viewedNews, newsId])
        );
      } else {
        console.log("News already viewed");
      }
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  };

  return (
    <>
      <div class="col-lg-9">
        <div className="row">
          {loading ? (
            <>
              <NewsContentSkeleton />
              <NewsContentSkeleton />
            </>
          ) : (
            newsData &&
            newsData
              ?.filter((item) => item.isActive && new Date(item.endDate) > new Date())
              ?.map((item) => {
                const encodedTitle = item?.tags
                  ? encodeURIComponent(
                      item?.tags
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9\s]/g, "")
                        .replace(/\s+/g, "-")
                        .replace(/-{2,}/g, "-")
                        .trim()
                    )
                  : "";
                return (
                  <div className="col-md-6" key={item.newsId}>
                    <Link
                      to={`/news/${item.newsId}/${encodedTitle}`}
                      style={{ textDecoration: "none" }}
                      onClick={() =>
                        activityViewerFunc(item.newsId, encodedTitle)
                      }
                    >
                      <div class="card card-shadow p-0">
                        <div class="score-card score-card-lg p-0">
                          <div class="score-card-inner px-20 py-20">
                            <div class="score-card-header mb-15">
                              <h1>{item?.tags}</h1>
                              <figure>
                                <img
                                  src={item?.image || "/assets/images/flag.png"}
                                  style={{ height: "350px", width: "100%" }}
                                  alt=""
                                />
                              </figure>
                              <strong className="my-2">{item?.title}</strong>
                              {/* <span>
                                {
                                  convertDateTimeUTCToLocal(item?.startDate)
                                    .localDate
                                }
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </>
  );
};

export default NewsContent;
