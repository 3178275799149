import React, { useEffect, useState } from "react";
import ScoreCard from "../ScoreCard";
import axiosInstance from "../../Features/axios";
import socket from "../../Features/socket";
import { COMMENTARY_CONNECTION, COMMENTARY_DISCONNECTION, CONNECT } from "../../utilities/const";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FreeMode } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../banner.css";
import { useLocation } from "react-router-dom";
import { sortMatchesByDateTime } from "../Reusables/Methods";
import { getMatchType } from "../../utilities/functions";
import ScoreHeaderSkeleton from "../Skeleton/ScoreHeaderSkeleton";

const ScoreHeader = () => {
  const [liveMatchData, setLiveMatchData] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const removeCard = (eventId) => {
    setLiveMatchData(prev => prev.filter(match => match?.eid !== eventId))
    setEventIds(prev => prev.filter(eid => eid !== eventId))
  }

  const fetchLiveMatchData = async () => {
    try {
      let apiCalls = [
        axiosInstance.post(`/admin/match/liveMatchesList`),
        axiosInstance.post(`/admin/match/comingSoonMatchesList`),
        axiosInstance.post(`/admin/match/scheduleMatchesList`)
      ];
      const responses = await Promise.allSettled(apiCalls)
      const newEventIds = [];
      const newMatches = []
      responses.forEach(response => {
        const result = response?.value?.data?.result;
        if (result) {
          const data = sortMatchesByDateTime(result, false);
          newMatches.push(...data);
          newEventIds.push(...result?.map((match) => match?.eid));
        }
      })
      setLiveMatchData(newMatches);
      setEventIds(newEventIds);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    fetchLiveMatchData();
  }, [location.pathname]);
  
  useEffect(() => {
    if (eventIds.length && socket) {
      if (socket?.connected) {
        socket.emit(COMMENTARY_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(COMMENTARY_CONNECTION, eventIds);
        });
      }
    }
    return () => {
      if (socket) {
        socket?.emit(COMMENTARY_DISCONNECTION, eventIds);
      }
    }
  }, [eventIds]);
  const today = new Date().toISOString().split("T")[0];

  return (
    <section className="header-middle d-flex align-items-center">
      {/* <div className="ml-10">
        <img src={"/assets/images/batter.png"} alt="batter" style={{ height: "130px", borderRadius: "100%"}}/>
      </div> */}
      <div className="container">
        <div className="header-cards" style={{ position: "relative" }}>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            className="mySwiper"
            centeredSlidesBounds={false}
            centeredSlides={false}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            modules={[FreeMode, Navigation]}
            breakpoints={{
              // when window width is >= 320px
              200: {
                slidesPerView: 1,
                spaceBetween: 10, // Adjust this value for smaller screens if needed
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 20, // Adjust this value for medium screens if needed
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 1,
                spaceBetween: 30, // Adjust this value for larger screens if needed
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 2,
                spaceBetween: 30, // Adjust this value for extra-large screens if needed
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 30, // Adjust this value for extra-large screens if needed
              },
            }}
          >
            {loading ? (
              <>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
                <SwiperSlide>
                  <ScoreHeaderSkeleton />
                </SwiperSlide>
              </>
            ) : (
              liveMatchData
                ?.filter((cardData) => {
                  if (getMatchType(cardData?.cst) === "LIVE") {
                    return true; 
                  } else if (getMatchType(cardData?.cst) === "UPCOMMING") {
                    const matchDate = cardData?.utc.split("T")[0];
                    return matchDate === today;
                  }
                  return false;
                })
                ?.map((cardData, i) => (
                  <SwiperSlide>
                    <ScoreCard
                      key={i}
                      cardData={cardData}
                      removeCard={removeCard}
                    />
                  </SwiperSlide>
                ))
            )}
          </Swiper>
          <div
            className="swiper-button-prev"
            style={{ position: "absolute", left: -20 }}
          ></div>
          <div
            className="swiper-button-next"
            style={{ position: "absolute", right: -20 }}
          ></div>
        </div>
      </div>
      {/* <div className="mr-10">
        <img src={"/assets/images/bowler.png"} alt="batter" style={{ height: "130px", borderRadius: "100%"}}/>
      </div> */}
    </section>
  );
};

export default ScoreHeader;
