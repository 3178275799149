import { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import socket from "../Features/socket";
import {
  COMMENTARY_CONNECTION,
  COMMENTARY_DISCONNECTION,
  CONNECT,
  LIVE,
  RESULT,
  UPCOMMING,
} from "../utilities/const";
import { sortMatchesByDateTime } from "./Reusables/Methods";
import MatchContentSkeleton from "./Skeleton/MatchContentSkeleton";
import NewsMatchSkeleton from "./Skeleton/NewsMatchSkeleton";
import MatchContentNav from "./MatchContentNav";

const MatchContent = ({ matchType, newsContent, loading, setLoading }) => {
  const [matchData, setMatchData] = useState([]);
  const [eventIds, setEventIds] = useState([]);

  const fetchLiveMatchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/admin/match/liveMatchesList`);
      if (response.data?.result) {
        const data = sortMatchesByDateTime(response.data?.result);
        setMatchData(data || []);
        setEventIds(data?.map((match) => match?.eid));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUpCommingMatchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `/admin/match/scheduleMatchesList`
      );
      if (response.data?.result) {
        const data = sortMatchesByDateTime(response.data?.result);
        setMatchData(data || []);
        setEventIds(data?.map((match) => match?.eid));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchResultMatchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `/admin/match/completeMatchesList`
      );
      if (response.data?.result) {
        const data = sortMatchesByDateTime(response.data?.result, false);
        setMatchData(data || []);
        setEventIds(data?.map((match) => match?.eid));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (matchType === LIVE) {
      fetchLiveMatchData();
    } else if (matchType === UPCOMMING) {
      fetchUpCommingMatchData();
    } else {
      fetchResultMatchData();
    }
  }, [matchType]);

  useEffect(() => {
    if (eventIds.length && matchType !== RESULT && socket) {
      if (socket?.connected) {
        socket.emit(COMMENTARY_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(COMMENTARY_CONNECTION, eventIds);
        });
      }
    }
    return () => {
      if (socket) {
        socket?.emit(COMMENTARY_DISCONNECTION, eventIds);
      }
    };
  }, [eventIds]);

  return (
    <div class="tab-content">
      <div id="live_match" class="tab-pane fade in show active">
        {newsContent && loading ? (
          <>
            <NewsMatchSkeleton />
            <NewsMatchSkeleton />
            <NewsMatchSkeleton />
          </>
        ) : loading ? (
          <>
            <MatchContentSkeleton />
            <MatchContentSkeleton />
            <MatchContentSkeleton />
          </>
        ) : (
          <MatchContentNav matchType={matchType} matchData={matchData} newsContent={newsContent}/>
        )}
      </div>
    </div>
  );
};

export default MatchContent;
