import { useEffect, useState } from "react";
import MatchContent from "./MatchContent";
import { LIVE, RESULT, UPCOMMING } from "../utilities/const";
import { Link } from "react-router-dom";
import axiosInstance from "../Features/axios";

const MatchMainSection = () => {
  const [matchType, setMatchType] = useState("LIVE");
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchPagesContent = async () => {
    try {
      const response = await axiosInstance.post(
        `/admin/menuItem/getPageContent`,
        {
          alias: "/live-score",
        }
      );
      const pageContent = response.data?.result;
      setPageData(pageContent);
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  };

  useEffect(() => {
    fetchPagesContent();
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [matchType]);

  return (
    <div class="col-lg-9">
      <section class="live-matches pt-0">
        <div class="card card-shadow">
          <ul class="nav nav-tabs mb-0">
            <li class="active">
              <Link
                data-toggle="tab"
                to={"/#"}
                class="active"
                onClick={() => setMatchType(LIVE)}
              >
                Live
              </Link>
            </li>
            <li>
              <Link data-toggle="tab" onClick={() => setMatchType(UPCOMMING)}>
                Upcoming
              </Link>
            </li>
            <li>
              <Link data-toggle="tab" onClick={() => setMatchType(RESULT)}>
                Result
              </Link>
            </li>
          </ul>
        </div>
        <MatchContent
          matchType={matchType}
          loading={loading}
          setLoading={setLoading}
        />
      </section>
    </div>
  );
};

export default MatchMainSection;
