import React from 'react'
import TopHeader from './TopHeader';
import ScoreHeader from './ScoreHeader';
import MainHeader from './MainHeader';

function Header() {
    return (
        <header className="header">
            <TopHeader />
            <ScoreHeader />
            <MainHeader />
        </header>
    );
}

export default Header;