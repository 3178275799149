import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MainHeaderSkeleton = () => {
  return (
    <>
      <li className="mx-3 py-4"> <Skeleton width={80} height={20} className='my-2'/></li>
      <li className="mx-3 py-4"> <Skeleton width={80} height={20} className='my-2'/></li>
      <li className="mx-3 py-4"> <Skeleton width={80} height={20} className='my-2'/></li>
      <li className="mx-3 py-4"> <Skeleton width={80} height={20} className='my-2'/></li>
    </>
  )
}

export default MainHeaderSkeleton