import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import "./FullscoreSidebar.css";

const FullScoreSidebarContentSkeleton = ({login}) => {
  return (
    <div className="acr-body">
      <div class="card card-shadow p-0 rounded-20 position-relative">
        <h5 className="px-10 py-10"></h5>
        {login && (
          <div className="login-container">
            <Link to="/login" className="btn btn-primary px-4 py-2">
              Login
            </Link>
          </div>
        )}
        <div className="table-responsive table-scrollbar">
          <table className="widget-table table market-table table-striped no-border">
            <thead>
              <tr>
                <th scope="col">Session</th>
                <th scope="col">Open</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton width={120} />
                </td>
                <td>
                  <Skeleton width={40} />
                </td>
                <td>
                  <Skeleton width={40} height={40} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton width={120} />
                </td>
                <td>
                  <Skeleton width={40} />
                </td>
                <td>
                  <Skeleton width={40} height={40} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton width={120} />
                </td>
                <td>
                  <Skeleton width={40} />
                </td>
                <td>
                  <Skeleton width={40} height={40} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton width={120} />
                </td>
                <td>
                  <Skeleton width={40} />
                </td>
                <td>
                  <Skeleton width={40} height={40} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FullScoreSidebarContentSkeleton;