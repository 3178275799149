export const BALL_TYPE_OVER_COMPLETE = 0;
export const BALL_TYPE_REGULAR = 1;
export const BALL_TYPE_WIDE = 2; // WB
export const BALL_TYPE_BYE = 3; // BY
export const BALL_TYPE_LEG_BYE = 4; // LBY
export const BALL_TYPE_NO_BALL = 5; // NB
export const BALL_TYPE_NO_BALL_BYE = 6; // NBY
export const BALL_TYPE_NO_BALL_LEG_BYE = 7; // NLB
export const BALL_TYPE_PANELTY_RUN = 8;
export const CONNECT = "connect";
export const COMMENTARY_CONNECTION = "commentaryConnection";
export const COMMENTARY_DISCONNECTION = "commentaryDisconnection";
export const EVENT_DATA = "eventData";
export const DISCONNECT = "disconnect";
export const LIVE = "LIVE";
export const UPCOMMING = "UPCOMING";
export const RESULT = "RESULT";
export const FULLSCORE_CONNECTION = "onFullScoreConnection";
export const FULLSCORE_DATA = "fullscoreData";
export const CONNECT_MARKET = "connectMarket";
export const UPDATE_EVENT_MARKET = "updateEventMarket";
export const CONNECT_EVENT = "connectEvent";
export const UPDATE_EVENT = "updateEvent";
export const REMEMBER_ME_KEY = "rememberMe";
export const USER_DATA_KEY = "userData";
export const USER_CRED = "userCred";
export const USER_LOGGED_IN = "onUserLoggedIn";
export const SEND_NOTIFICATION = "sendNotification";


export const OTHER_BALLS = {
    [BALL_TYPE_WIDE]: "WB",
    [BALL_TYPE_BYE]: "B",
    [BALL_TYPE_LEG_BYE]: "LB",
    [BALL_TYPE_NO_BALL]: "NB",
    [BALL_TYPE_NO_BALL_BYE]: "NBB",
    [BALL_TYPE_NO_BALL_LEG_BYE]: "NLB"
}

export const OTHER_BALLS_FULL_NAME = {
    [BALL_TYPE_WIDE]: "WIDE",
    [BALL_TYPE_BYE]: "BYE",
    [BALL_TYPE_LEG_BYE]: "LEG BYE",
    [BALL_TYPE_NO_BALL]: "NO BALL",
    [BALL_TYPE_NO_BALL_BYE]: "NO BALL BYE",
    [BALL_TYPE_NO_BALL_LEG_BYE]: "NO BALL LEG BYE"
}

export const STRING_NAME = {
    0: "no",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six"
}

export const MATCH_SECTIONS = {
    LIVE: "LIVE",
    SCORECARD: "SCORECARD",
    COMMENTARY: "COMMENTARY",
    TEAM: "TEAM",
    PARTNERSHIP: "PARTNERSHIP",
    OVERS: "OVERS"
}