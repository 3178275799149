import { getMatchDetails, getRunsAndBalls, wicketType } from "../utilities/functions";
import { Link } from "react-router-dom";
import { PlayerImageInOvers, PlayerImageInScoreCard } from "./PlayerImage";
import ScoreCardSkeleton from "./Skeleton/ScoreCardSkeleton";

const ScoreCardDetails = ({ scoreCardData, loading }) => {
  const getDidNotBat = (sqt, bat) =>
    sqt
      ?.filter(
        (sqtData) => !bat?.some((playerData) => sqtData.commentaryPlayerId === playerData.commentaryPlayerId)
      )
      .sort((a, b) => a.ord - b.ord);
  const PlayerImages = (playerId) => {
    const playerImage = [...scoreCardData?.sqt1, ...scoreCardData?.sqt2]?.find((val) => {
      return val?.commentaryPlayerId === playerId;
    });
    return playerImage?.playerimage;
  };
  const teamJersay = (teamId) =>
    scoreCardData?.team1Id === teamId
      ? scoreCardData?.t1jr
      : scoreCardData?.t2jr;
  
  const getInningsData = (innings) => {
    const inningsKey = `cci${innings}`;
    return scoreCardData?.cci?.[inningsKey];
  };

  return (
    <> {loading ? (
        <ScoreCardSkeleton />
      ) : (
    scoreCardData?.td.length > 0 ? (
      <div className="accordion" id="accordion">
        {scoreCardData?.td?.map((team, index) => {
          const inningsData = getInningsData(team.currentInnings);
          let bat = [];
          let bow = [];
          let fow = [];
          if(scoreCardData.es.currentInnings === 2 && team.currentInnings === 1) {
            bat = team.teamId === scoreCardData?.prevBaid
            ? inningsData?.bat1
            : inningsData?.bat2;
            
            bow = team.teamId === scoreCardData?.prevBoid
              ? inningsData?.bow1
              : inningsData?.bow2;

            fow = team.teamId === scoreCardData?.prevBaid
              ? inningsData?.fow1
              : inningsData?.fow2;
          } else {
            bat = team.teamId === scoreCardData?.baid
            ? inningsData?.bat1
            : inningsData?.bat2;
            
            bow = team.teamId === scoreCardData?.boid
              ? inningsData?.bow1
              : inningsData?.bow2;

            fow = team.teamId === scoreCardData?.baid
              ? inningsData?.fow1
              : inningsData?.fow2;
          }
          const sqt =
            (team.commentaryTeamId === scoreCardData?.t1id || team.commentaryTeamId === scoreCardData?.t3id)
              ? scoreCardData?.sqt1
              : scoreCardData?.sqt2;
          const ts = getMatchDetails(
            (team.commentaryTeamId === scoreCardData?.t1id)
              ? scoreCardData?.t1s
              : (team.commentaryTeamId === scoreCardData?.t2id)
              ? scoreCardData?.t2s
              : (team.commentaryTeamId === scoreCardData?.t3id)
              ? scoreCardData?.t3s
              : scoreCardData?.t4s
            );
          const dnb = getDidNotBat(sqt, bat);

          return (
            <div className="accordion-item">
              <h5
                className=""
                data-toggle="collapse"
                data-target={`#bd_innings-${index}`}
                aria-expanded="true"
              >
                <div className="flag-avatar">
                  <figure>
                    <img
                      src={team.image || "/assets/images/flag.png"}
                      alt="Team 1 Image"
                    />
                  </figure>
                </div>
                {team.teamName}
              </h5>
              <div id={`bd_innings-${index}`} className="collapse show">
                <div className="acr-body">
                  <div className="card card-shadow p-0">
                    <div className="table-responsive">
                      <table className="widget-table table table-striped table-medium no-border">
                        <thead>
                          <tr>
                            <th scope="col">Batsmen</th>
                            <th scope="col"></th>
                            <th scope="col">r</th>
                            <th scope="col">b</th>
                            <th scope="col" style={{ textTransform: "lowercase"}}>4s</th>
                            <th scope="col" style={{ textTransform: "lowercase"}}>6s</th>
                            <th scope="col">sr</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bat?.sort((a, b) => a.batterOrder - b.batterOrder)?.map((data) => (
                            <tr>
                              <td className="d-flex align-items-center">
                              <div className="mr-4">
                                  <PlayerImageInScoreCard
                                    width="35px"
                                    playerImage={PlayerImages(data?.commentaryPlayerId)}
                                    jerseyImage={
                                      teamJersay(data?.teamId) ||
                                      "/images/SampleJersy.png"
                                    }
                                  />
                                </div> 

                                {!data.wicketType ?<strong>{data.playerName}</strong>:<span>{data.playerName}</span>}
                                </td>
                              <td>{!data.wicketType ?null:wicketType[data.wicketType]}</td>
                              <td>
                                <span>
                                  {data?.batRun || 0}
                                </span>
                              </td>
                              <td>{data?.batBall || 0}</td>
                              <td>{data?.batFour || 0}</td>
                              <td>{data?.batSix || 0}</td>
                              <td>{data?.batsmanStrikeRate ? parseFloat(data.batsmanStrikeRate).toFixed(2) : "0.00"}</td>
                            </tr>
                          ))}
                          <tr className="score-text-bold">
                            <td>Total Score</td>
                            <td>
                              {ts?.wickets} wkts,{" "}
                              {parseFloat(ts?.overs).toFixed(1)} ov
                            </td>
                            <td className="text-left" colspan="5">
                              {ts?.runs}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="spell-sum-box px-30 py-20">
                        <h5>
                          Did not bat:
                          <span className="ml-2">
                            {dnb?.map((item, index) =>
                              index !== dnb?.length - 1
                                ? item.playerName + ", "
                                : item.playerName
                            )}
                          </span>
                        </h5>
                        <h5 className="d-sm-flex">
                          Fall of Wickets:
                          <span className="ml-05">
                            {fow?.map(
                              (data, index) =>
                                data.teamScore +
                                "-" +
                                data.wicketCount +
                                " (" +
                                data.batterName +
                                ")" +
                                (index !== fow?.length - 1 ? ", " : "")
                            )}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="card card-shadow p-0">
                    <div className="table-responsive">
                      <table className="widget-table table table-striped table-medium no-border">
                        <thead>
                          <tr>
                            <th scope="col">Bowlers</th>
                            <th scope="col">o</th>
                            <th scope="col">m</th>
                            <th scope="col">r</th>
                            <th scope="col">w</th>
                            <th scope="col">econ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bow
                            ?.filter((a) => parseFloat(a.bowlerOver) > 0)?.sort((a, b) => a.bowlerOrder - b.bowlerOrder)
                            .map((data) => (
                              <tr>
                                <td className="d-flex align-items-center">
                                <div className="mr-4">
                                  <PlayerImageInScoreCard
                                    width="35px"
                                    playerImage={PlayerImages(data?.commentaryPlayerId)}
                                    jerseyImage={
                                      teamJersay(data?.teamId) ||
                                      "/images/SampleJersy.png"
                                    }
                                  />
                                </div> 
                                  {/* <Link to={"/#"}> */}
                                   {data?.isPlay? <span>{data.playerName}*</span> :  <span>{data.playerName}</span>}
                                  {/* </Link> */}
                                </td>
                                <td>
                                  <strong>
                                  {data?.bowlerOver ? parseFloat(data.bowlerOver).toFixed(1) : "0.0"}
                                  </strong>
                                </td>
                                <td>{data.bowlerMaidenOver || 0}</td>
                                <td>{data.bowlerRun || 0}</td>
                                <td>{data.bowlerTotalWicket || 0}</td>
                                <td>{data?.bowlerEconomy ? parseFloat(data.bowlerEconomy).toFixed(2) : "0.00"}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : (
      <div className="card card-shadow p-0">
        <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
          <strong> Match has not started yet </strong>
        </div>
      </div>
    )
  )}
  </>
  )
};

export default ScoreCardDetails;
