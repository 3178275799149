import React from "react";
import Skeleton from "react-loading-skeleton";

const LiveTeamSkeleton = () => {
  return (
    <div>
      <div className="accordion" id="accordion">
        <div className="accordion-item">
          <h5 className="d-flex align-items-center py-10">
            <Skeleton height={38} width={40} borderRadius={50} />
            <Skeleton width={200} className="mx-2" />
          </h5>
          <div id="ind_innings" className="collapse show">
            <div className="acr-body">
              <div className="card card-shadow p-0">
                <div class="row">
                  <div class="col-6 col-md-4">
                    <div className="card card-height">
                      <div class="d-flex align-items-center">
                        <Skeleton width={40} height={38} />
                        <div className="mx-2">
                          <div class="font-weight-bold">
                            <Skeleton width={50} />
                          </div>
                          <div>
                            <Skeleton width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-4">
                    <div className="card card-height">
                      <div class="d-flex align-items-center">
                        <Skeleton width={40} height={38} />
                        <div className="mx-2">
                          <div class="font-weight-bold">
                            <Skeleton width={50} />
                          </div>
                          <div>
                            <Skeleton width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h5 className="d-flex align-items-center py-10">
            <Skeleton height={38} width={40} borderRadius={50} />
            <Skeleton width={200} className="mx-2" />
          </h5>
          <div
            id="bd_innings"
            className="collapse show"
            // data-parent="#accordion"
          >
            <div className="acr-body">
              <div className="card card-shadow p-0">
                <div class="row">
                  <div class="col-6 col-md-4">
                    <div className="card card-height">
                      <div class="d-flex align-items-center">
                        <Skeleton height={38} width={40} />
                        <div className="mx-2">
                          <div class="font-weight-bold">
                            <Skeleton width={50} />
                          </div>
                          <div>
                            <Skeleton width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-4">
                    <div className="card card-height">
                      <div class="d-flex align-items-center">
                        <Skeleton height={38} width={40} />
                        <div className="mx-2">
                          <div class="font-weight-bold">
                            <Skeleton width={50} />
                          </div>
                          <div>
                            <Skeleton width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveTeamSkeleton;
