import React from "react";
import Skeleton from "react-loading-skeleton";
import "./Skeleton.css";

const ScoreHeaderSkeleton = () => {
  return (
    <div className="owl-item active score-header-skeleton">
      <div className="score-card p-0">
        <div className="score-card-inner-skeleton">
          <Skeleton />
          <Skeleton />
          <div className="score-card-body">
            <div className="country-info">
              <div>
                <Skeleton width={40} height={40} borderRadius={50} />
                <Skeleton width={40} />
              </div>
              <div className="score-update">
                <Skeleton width={40} />
                <Skeleton width={40} />
              </div>
            </div>
            <div className="country-info flex-row-reverse">
              <div className="ml-05">
                <Skeleton width={40} height={40} borderRadius={50} />
                <Skeleton width={40} />
              </div>
              <div className="score-update">
                <Skeleton />
                <Skeleton width={40} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Skeleton height={20} />
        </div>
      </div>
    </div>
  );
};

export default ScoreHeaderSkeleton;
