import React, { useEffect } from "react";
import NewsLayout from "../Layouts/NewsLayout";
import NewsDeatilsSection from "../Components/NewsDeatilsSection";
import { useNavigate } from "react-router-dom";

const NewsDetails = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);

  return (
    <NewsLayout>
      <NewsDeatilsSection />
    </NewsLayout>
  );
};

export default NewsDetails;
