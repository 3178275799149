// import ReachUs from "./ReachUs";

import FullScoreSidebarContent from "./FullScoreSidebarContent";


const FullscoreSideBar = () => {
  return (
    <div class="col-lg-3" style={{ paddingLeft: "5px", paddingRight: "5px"}}>
      <aside class="sidebar right-sidebar">
        <FullScoreSidebarContent />
      </aside>
    </div>
  )
}

export default FullscoreSideBar;