import React from "react";
import Skeleton from "react-loading-skeleton";

const BannerDetailsSkeleton = () => {
  return (
    <div class="card card-shadow p-0">
      <div class="score-card score-card-lg p-0">
        <div class="score-card-inner px-20 py-20">
          <div class="score-card-header">
            <Skeleton />
            <Skeleton height={456} width="100%" />
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerDetailsSkeleton;
