import React from 'react'
import { Link } from 'react-router-dom'

const QuickLinks = () => {
    return (
        <div class="foo-widget foo-navigation">
            <h3 class="widget-title">Quick Links</h3>
            <ul>
                <li><Link to={"/#"} >About us</Link></li>
                <li><Link to={"/#"} >contact us</Link></li>
                <li><Link to={"/#"} >Frequently Asked Questions</Link></li>
                <li><Link to={"/#"} >Terms &amp; Conditions</Link></li>
                <li><Link to={"/#"} >Privacy Policy</Link></li>
            </ul>
        </div>
    )
}

export default QuickLinks