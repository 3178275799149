import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  COMMENTARY_CONNECTION,
  CONNECT,
  DISCONNECT,
  EVENT_DATA,
} from "../utilities/const";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { Navigation } from "swiper/modules";
import socket from "../Features/socket";
import { getMatchDetails, getCurrentOverWithoutOverNo } from "../utilities/functions";
import axiosInstance from "../Features/axios";
import "./Scoreboard2.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { ScoreboardPlayerImage } from "../Components/PlayerImage";
import _ from "lodash";

function Scoreboard2() {
  let [searchParams] = useSearchParams();
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const eventId = searchParams.get("id");
  const [bowlers, setBowler] = useState([]);
  const [batsmen, setBatsmen] = useState([]);
  const [currentMatch, setCurrentMatch] = useState({});
  const [currentOver, setCurrentOver] = useState([]);
  const [isApproved, setIsApproved] = useState(true);
  // const [currentStyle, setCurrentStyle] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideHeight, setSlideHeight] = useState();
  // const [battingTeam, setBattingTeam] = useState({});
  // const [bowlingTeam, setBowlingTeam] = useState({});
  // const [animationFlag,setAnimationFlag] = useState(false);
  const [percentageLeft, setPercentageLeft] = useState(0);
  const [percentageRight, setPercentageRight] = useState(0);
  const [isPer, setIsPer] = useState(false)
  const elemRef = useRef();
  const styleRef = useRef();
  const swiperRef = useRef(null);
  // const navigate = useNavigate();

  // const updateStyleWithTimeout = (newStyle) => {
  //   setCurrentStyle(newStyle);
  //   setTimeout(() => {
  //     setCurrentStyle(null);
  //     setAnimationFlag(false)
  //   }, 4500);
  // };

  const handleSlideClick = (index) => {
    if (currentMatch?.ics) {
      setCurrentSlide(index);
      swiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    let bgColor = searchParams.get("color");
    const fontColor = searchParams.get("font");
    bgColor =
      !bgColor || bgColor === "NULL" || bgColor === "#null"
        ? "transparent"
        : `#${bgColor}`;
    styleRef.current = {
      backgroundColor: bgColor,
      color: `#${fontColor}`,
    };
  }, [searchParams]);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '334171585841519');
      fbq('track', 'PageView');
    `;
    
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const customStyles = styleRef.current && {
    backgroundColor: styleRef.current.backgroundColor,
    color: styleRef.current?.color !== "#null" && styleRef.current.color,
  };

  useEffect(() => {
    async function fetchData() {
      const iframes = window?.location?.ancestorOrigins;
      try {
        let urlString;
        if (iframes && iframes.length > 0) {
          const iframeLength = iframes.length;
          urlString = iframes[iframeLength - 1];
        } else {
          urlString = window.parent.location;
        }

        if (urlString) {
          var url = new URL(urlString);
          var domain = url.hostname;

          domain = domain
            .replace("https://www.", "")
            .replace("http://www.", "")
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "")
            .replace("/", "");

          var parts = domain.split(".");
          const siteName = parts.slice(-2).join(".");
          const siteDomain = parts.slice(-2).join(".");
          const subdomains =
            parts.length > 2 ? parts.slice(0, parts.length - 2) : [];

          const payload = {
            siteName: siteName,
            siteDomain: siteDomain,
            subDomains: subdomains,
          };

          const response = await axiosInstance.post(
            `/admin/domain/checkApprove`,
            payload
          );
          setIsApproved(response.data.result.isApproved);
        } else {
          console.error("Parent referrer URL is not available.");
        }
      } catch (error) {
        console.error("Error fetching parent domain:", error);
      }
    }
    const intervalId = setTimeout(fetchData, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  useEffect(() => {
    if (window.parent && window.parent.postMessage) {
      const elemHeight = elemRef?.current?.clientHeight || 140;
      let curURL;
      const iframes = window?.location?.ancestorOrigins;
      if (iframes && iframes.length > 0) {
        const iframeLength = iframes.length;
        curURL = iframes[iframeLength - 1];
      } else if (window.parent.location) {
        curURL = window.location.hostname;
      } else {
        console.error("Unable to determine current URL.");
        return;
      }

      window.parent.postMessage(
        {
          scoreWidgetHeight: +elemHeight,
          curURL: curURL,
        },
        "*"
      );
    }
  }, [eventId, slideHeight]);

  useEffect(() => {
    const currentBall = [...(currentMatch?.currOver || [])].pop() || {};
    if (
      !_.isEqual(currentOver, currentMatch.currOver) &&
      (currentBall.isb || currentBall.isw)
    ) {
      setCurrentOver(currentMatch.currOver);
      // if (currentOver?.length <= currentMatch?.currOver?.length)
      //   generateActionAnimation(currentBall, currentMatch);
    }
  }, [currentMatch]);

  const configSocket = () => {
    socket.emit(COMMENTARY_CONNECTION, [eventId]);
    socket.on(EVENT_DATA, (data) => {
      const eventData = data?.value;
      if (eventData) {
        setIsDataAvailable(true);
        let { cm, ...rest } = eventData;
        cm.t1md = getMatchDetails(cm.t1s);
        cm.t2md = getMatchDetails(cm.t2s);
        cm.currOver = getCurrentOverWithoutOverNo(cm.scov, eventData.cbb);
        // const battingTeam = cm.scot === cm.t1sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        // const bowlingTeam = cm.scot === cm.t2sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }

        setBowler(eventData.cbl);
        setBatsmen(eventData.cbt);
        setCurrentMatch(eventData.cm);
        setPercentageLeft(parseFloat(eventData.cm?.tpp1));
        setPercentageRight(parseFloat(eventData.cm?.tpp2));
        setIsPer(parseFloat(eventData.cm?.tpp1) && parseFloat(eventData.cm?.tpp2))
        setSlideHeight(elemRef?.current?.clientHeight);
        // setBattingTeam(battingTeam)
        // setBowlingTeam(bowlingTeam)
      }
    });
    socket.on(DISCONNECT, (reason) => {
      console.log("socket disconnected", reason);
    });
  };
  async function fetchScoreDataById() {
    try {
      const response = await axiosInstance.post(`/admin/match/getscoreByEId`, {
        eventId,
      });
      const eventData = response.data;
      if (eventData) {
        setIsDataAvailable(true);
        let { cm, ...rest } = eventData;
        cm.t1md = getMatchDetails(cm.t1s);
        cm.t2md = getMatchDetails(cm.t2s);
        cm.currOver = getCurrentOverWithoutOverNo(cm.scov, eventData.cbb);
        // const battingTeam = cm.scot === cm.t1sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }
        // const bowlingTeam = cm.scot === cm.t2sn ? { bgColor: cm?.t1bg, borderColor: cm?.t1co } : { bgColor: cm?.t2bg, borderColor: cm?.t2co }

        setBowler(eventData.cbl);
        setBatsmen(eventData.cbt);
        setCurrentMatch(eventData.cm);
        setPercentageLeft(parseFloat(eventData.cm?.tpp1));
        setPercentageRight(parseFloat(eventData.cm?.tpp2));
        setIsPer(parseFloat(eventData.cm?.tpp1) && parseFloat(eventData.cm?.tpp2))
        setSlideHeight(elemRef?.current?.clientHeight);
        // setBattingTeam(battingTeam)
        // setBowlingTeam(bowlingTeam)
      }
    } catch (error) {
      console.error("Error fetching score by ID:", error);
    }
  }

  //  const generateActionAnimation = (currentBall, currentMatch) => {
  //   let cutomStyle = {};
  //   if (currentBall?.isb) {
  //     cutomStyle = {
  //       text: +currentBall?.run === 4 ? "Four" : "Six",
  //       image: +currentBall?.run === 4 ? "/images/four.png" : "/images/six.png",
  //        ...battingTeam
  //       }
  //     setAnimationFlag(true)
  //   } else {
  //     cutomStyle = {
  //       text: "Wicket!",
  //       image: "/images/out.png",
  //      ...bowlingTeam}
  //     setAnimationFlag(true)
  //   }
  //   updateStyleWithTimeout(cutomStyle);
  // };

  useEffect(() => {
    if (eventId && isApproved) {
      fetchScoreDataById();
      if (socket) {
        if (socket?.connected) {
          configSocket();
        } else {
          socket.on(CONNECT, () => {
            configSocket();
          });
        }
        return () => {
          if (socket) {
            socket.disconnect();
          }
        };
      } else {
        const intervalTime = setInterval(
          fetchScoreDataById,
          process.env.API_INTERVAL || 3000
        );
        return () => {
          clearInterval(intervalTime);
        };
      }
    }
  }, [eventId, isApproved]);

  // useEffect(() => {
  //   setSlideHeight(elemRef?.current?.clientHeight)
  // }, [currentSlide,animationFlag]);

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setSlideHeight(elemRef?.current?.clientHeight);
    }
  }, [currentSlide]);

  useEffect(()=>{
    setSlideHeight(elemRef?.current?.clientHeight);
  },[isPer])

  useEffect(() => {
    const handleResize = () => {
      setSlideHeight(elemRef?.current?.clientHeight);
      setTimeout(() => {
        setSlideHeight(elemRef?.current?.clientHeight);
      }, 0);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);

  return (
    eventId &&
    isDataAvailable && (
      <>
        {!isApproved && (
          <div className="message-container">
            <div className="trial-message">You are using trial version</div>
          </div>
        )}
        <div className="scoreboard2 bg-transparent">
          <div className="row m-0 bg-transparent">
            <div className="col-center col s12 board-css">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                className="mySwiper"
                centeredSlidesBounds={false}
                centeredSlides={false}
                modules={[FreeMode, Navigation]}
                navigation={currentMatch?.ics}
                breakpoints={{
                  // when window width is >= 320px
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
                allowSlidePrev={currentMatch?.ics}
                allowSlideNext={currentMatch?.ics}
                ref={swiperRef}
                onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
                // style={{ height: slideHeight && slideHeight }}
                style={{
                  height: slideHeight && slideHeight,
                  backgroundColor: customStyles?.backgroundColor,
                  color: customStyles?.color && customStyles.color,
                }}
              >
                <SwiperSlide>
                  <div
                    className="dynamic-score backgound-banner"
                    ref={currentSlide === 0 ? elemRef : null}
                  // style={{ height: window.innerWidth > 600 && slideHeight }}
                  >
                    {/* {currentStyle && (
                      <div
                        style={{
                          color: `${currentStyle?.borderColor || "black"}`,
                        }}
                        className="foreground-text"
                      >
                        {currentStyle.text}
                      </div>
                    )}
                    {currentStyle && (
                      <img
                        className="umpire-image"
                        src={currentStyle?.image}
                        alt="Umpire"
                      ></img>
                    )} */}
                    <div
                      className={`selectbet-header `}
                    // style={
                    //   currentStyle
                    //     ? {
                    //         border: `5px solid ${currentStyle?.borderColor}`,
                    //         backgroundColor: `${currentStyle?.bgColor}`,
                    //       }
                    //     : {
                    //         backgroundColor: customStyles?.backgroundColor,
                    //         color: customStyles?.color && customStyles.color,
                    //       }
                    // }
                    // style={{
                    //   backgroundColor: customStyles?.backgroundColor,
                    //   color: customStyles?.color && customStyles.color,
                    // }}
                    >
                      {/* <img className="banner-bg-image" src="/images/CricketSlide1.png" alt="background-img"></img> */}
                      <div className="event-name">
                        <div className="d-flex bet-text">
                          <div className="width-40">
                            <div className="left-team-title">
                              <div className="team-details" style={{ alignItems: window.innerWidth <= 600 && "center" }}>
                                <div className="team-img-1">
                                  <img
                                    src={
                                      currentMatch?.t1im ||
                                      (currentMatch?.scot === currentMatch?.t1sn
                                        ? "images/batting.png"
                                        : "images/bowling.png")
                                    }
                                    alt="batting"
                                    className="left-icon team1Side"
                                  />
                                </div>
                                {window.innerWidth <= 600 ? (
                                  <div>
                                    <div className="team-title-full">
                                      {currentMatch?.t1sn}
                                    </div>
                                    <div className="team-run">
                                      <span>
                                        {currentMatch?.t1md?.runs}-
                                        {currentMatch?.t1md?.wickets}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="team-title-full">
                                      {currentMatch?.t1sn}
                                    </div>
                                    <div className="team-run">
                                      <span>
                                        {currentMatch?.t1md?.runs}-
                                        {currentMatch?.t1md?.wickets}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="bestment"></div>
                              <div className="team-score">
                                <p>
                                  (
                                  {Number(currentMatch?.t1md?.overs).toFixed(1)}{" "}
                                  OV)
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="center-content">
                            {/* {!currentStyle && ( */}
                            <div className={`ball-counter ${(currentMatch?.dis === '4' || currentMatch?.dis === '6' || currentMatch?.dis === 'Wicket') && 'display-status-blink'}`}>
                              <p>{currentMatch?.dis === "4" ? "Four" : currentMatch?.dis === "6" ? "Six" : currentMatch?.dis}</p>
                              <p>{currentMatch?.win}</p>
                            </div>
                            {/* )} */}
                            <div className="balls">
                              <ul>
                                {currentMatch?.currOver?.map((value, index) => (
                                  <li
                                    key={index}
                                    className={`ball ${value.ballType}`}
                                  >
                                    {value.run}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="width-40">
                            <div className="right-team-title">
                              <div className="team-details" style={{ alignItems: window.innerWidth <= 600 && "center" }}>
                                <span className="team-img-1">
                                  <img
                                    src={
                                      currentMatch?.t2im ||
                                      (currentMatch?.scot === currentMatch?.t2sn
                                        ? "images/batting.png"
                                        : "images/bowling.png")
                                    }
                                    alt="bowling"
                                    className="right-icon team2Side"
                                  />
                                </span>
                                {window.innerWidth <= 600 ? (
                                  <div>
                                    <div className="team-title-full">
                                      {currentMatch?.t2sn}
                                    </div>
                                    <div className="team-run">
                                      <span>
                                        {currentMatch?.t2md?.runs}-
                                        {currentMatch?.t2md?.wickets}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="team-title-full">
                                      {currentMatch?.t2sn}
                                    </div>
                                    <div className="team-run">
                                      <span>
                                        {currentMatch?.t2md?.runs}-
                                        {currentMatch?.t2md?.wickets}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="bestment"></div>

                              <div className="team-score">
                                <p>
                                  (
                                  {Number(currentMatch?.t2md?.overs).toFixed(1)}{" "}
                                  OV)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="run-needs">{currentMatch?.rmk}</div>
                       {(percentageLeft && percentageRight) ?
                        <div className="progress-bars mt-lg-2">
                          <div className="progress-title d-flex flex-column align-items-center">
                            <div className="progress">
                              <div className="d-flex" style={{ width: `${percentageLeft}%`, backgroundColor: percentageLeft > percentageRight ? "green" : "red"}}></div>
                              <div className="d-flex" style={{ width: `${percentageRight}%`, backgroundColor: percentageRight > percentageLeft ? "green" : "red" }}></div>
                            </div>
                            <div className="d-flex justify-content-between progress-content">
                              <span className="progress-amount">({percentageLeft}%)</span>
                              <span className="progress-amount">Realtime Win %</span>
                              <span className="progress-amount">({percentageRight}%)</span>
                            </div>
                          </div>
                        </div>
                        : null}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="dynamic-score backgound-banner"
                    ref={currentSlide === 1 ? elemRef : null}
                  // style={{ height: window.innerWidth > 600 && slideHeight }}
                  >
                    {/* {currentStyle && (
                      <div
                        style={{
                          color: `${currentStyle?.borderColor || "black"}`,
                        }}
                        className="foreground-text"
                      >
                        {currentStyle.text}
                      </div>
                    )}
                    {currentStyle && (
                      <img
                        className="umpire-image"
                        src={currentStyle?.image}
                        alt="Umpire"
                      ></img>
                    )} */}
                    <div
                      className="selectbet-header banner-2"
                    // style={
                    //   currentStyle
                    //     ? {
                    //         border: `5px solid ${currentStyle?.borderColor}`,
                    //         backgroundColor: `${currentStyle?.bgColor}`,
                    //       }
                    //     : {
                    //         backgroundColor: customStyles?.backgroundColor,
                    //         color: customStyles?.color && customStyles.color,
                    //       }
                    // }
                    // style={{
                    //   backgroundColor: customStyles?.backgroundColor,
                    //   color: customStyles?.color && customStyles.color,
                    // }}
                    >
                      <div className="event-name">
                        <div className="d-flex bet-text">
                          <div className="width-40">
                            <div className="left-team-title">
                              <div className="score-card2">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>BATSMEN</th>
                                      <th>R</th>
                                      <th>B</th>
                                      <th>4S</th>
                                      <th>6S</th>
                                      <th>SR</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td colSpan="6" className="bestment"></td>
                                    </tr>
                                    {batsmen.map((batsman, index) => (
                                      <>
                                        <tr>
                                          <td className="player1">
                                            <div className="d-flex align-items-center">
                                              <ScoreboardPlayerImage
                                                width="22px"
                                                playerImage={batsman?.bati}
                                                jerseyImage={
                                                  currentMatch?.scot ===
                                                    currentMatch?.t1sn
                                                    ? currentMatch?.t1jr
                                                    : currentMatch?.t2jr
                                                }
                                              />
                                              <div className="playerName">
                                                {!batsman?.isp && batsman?.batn}
                                                {batsman?.os && "*"}
                                              </div>
                                            </div>
                                          </td>
                                          <td>{batsman?.trun}</td>
                                          <td>{batsman?.tball}</td>
                                          <td>{batsman?.t4}</td>
                                          <td>{batsman?.t6}</td>
                                          <td>{batsman?.str}</td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* { (batsmen.length === null & bowlers.length === null) && ( */}
                          {/* <div className="center-content"> */}
                          <div className="run-needs-2">
                            <span>{currentMatch?.rmk}</span>
                            <span>{currentMatch?.win}</span>
                          </div>
                          {/* { currentMatch?.dis &&
                            <div className="ball-counter-2">
                              <p>{currentMatch?.dis}</p>
                            </div>
                            } */}
                          {/* <div className="balls">
                              <ul>
                                {currentMatch?.currOver?.map((value, index) => (
                                  <li
                                    key={index}
                                    className={`ball ${value.ballType}`}
                                  >
                                    {value.run}
                                  </li>
                                ))}
                              </ul>
                            </div> */}
                          {/* </div> */}
                          {/* )} */}
                          <div className="width-40 ">
                            <div className="right-team-title right-team">
                              <div className="score-card2">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Bowler</th>
                                      <th>0</th>
                                      <th>M</th>
                                      <th>R</th>
                                      <th>W</th>
                                      <th>ECO</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td colSpan="6" className="bestment"></td>
                                    </tr>
                                    {bowlers.map((bowler, index) => (
                                      <tr>
                                        <td className="player1">
                                          <div className="d-flex align-items-center">
                                            <ScoreboardPlayerImage
                                              width="22px"
                                              playerImage={bowler?.bli}
                                              jerseyImage={
                                                currentMatch?.scot ===
                                                  currentMatch?.t2sn
                                                  ? currentMatch?.t1jr
                                                  : currentMatch?.t2jr
                                              }
                                            />
                                            <div className="playerName">
                                              {!bowler?.isp
                                                ? bowler?.pn
                                                : "***"}
                                            </div>
                                          </div>
                                        </td>
                                        <td>{parseFloat(bowler?.tov)}</td>
                                        <td>{bowler?.mov}</td>
                                        <td>{bowler?.trun}</td>
                                        <td>{bowler?.twik}</td>
                                        <td>{bowler?.eco}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                       {(percentageLeft && percentageRight) ?
                        <div className="progress-bars mt-lg-2">
                          <div className="progress-title d-flex flex-column align-items-center">
                            <div className="progress">
                              <div className="d-flex" style={{ width: `${percentageLeft}%`, backgroundColor: percentageLeft > percentageRight ? "green" : "red"}}></div>
                              <div className="d-flex" style={{ width: `${percentageRight}%`, backgroundColor: percentageRight > percentageLeft ? "green" : "red" }}></div>
                            </div>
                            <div className="d-flex justify-content-between progress-content">
                              <span className="progress-amount">({percentageLeft}%)</span>
                              <span className="progress-amount">Realtime Win %</span>
                              <span className="progress-amount">({percentageRight}%)</span>
                            </div>
                          </div>
                        </div>
                        : null}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <ol className="carousel-indicators">
                {Array.from({ length: 2 }).map((_, index) => (
                  <li
                    key={index}
                    className={currentSlide === index ? "active" : ""}
                    onClick={() => handleSlideClick(index)}
                  ></li>
                ))}
              </ol>
            </div>
          </div>
        </div>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=334171585841519&ev=PageView&noscript=1`}
            alt=""
          />
        </noscript>
      </>
    )
  );
}

export default Scoreboard2;
