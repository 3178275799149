import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import NewsSideBar from "../Components/NewsSideBar";

const NewsLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div id="main" class="main-container">
        <div class="container">
          <div class="row">
            {children}
            <NewsSideBar />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsLayout;
