import React, { useEffect, useState } from "react";
import { getCommentaryRun, getSocketFullRuns, getRunClass } from "../utilities/functions";
import PlayerImage from "./PlayerImage";
import CommentaryOverSkeleton from "./Skeleton/CommentaryOverSkeleton";
import { OTHER_BALLS } from "../utilities/const";
import { convertDateUTCToLocal } from "./Reusables/Methods";

const CommentaryDetails = ({ commentaryOverData, loading }) => {
    const [token, setToken] = useState("");

    useEffect(()=>{
        const tokenVal = localStorage.getItem("accessToken");
        setToken(tokenVal || "")
    }, [])
    const getPlayerImage = (sqt, pid) => {
        let player = sqt?.find(player => player.commentaryPlayerId === pid);
        return player?.playerimage;
    }
    const getPlayerName = (sqt, playerId) => {
        if (sqt && playerId) {
          let playerName = sqt?.find((val) => {
            return val.commentaryPlayerId === playerId;
          });
          return playerName?.playerName;
        }
      };
    const TeamName = (teamId) => {
      const teamName = commentaryOverData?.teamsData?.find((val) => {
        return val?.teamId !== teamId;
      });
      return teamName;
    };
    return  loading ? (
        <CommentaryOverSkeleton />
      ) : ( commentaryOverData?.td.length > 0 ? (
            <div className="accordion" id="accordion">
                {commentaryOverData?.td?.filter((value => value.teamBattingOrder !== null))?.map((data, index) => {
                    const sqt1 = ((data.commentaryTeamId === commentaryOverData?.t1id || data.commentaryTeamId === commentaryOverData?.t3id) ? commentaryOverData?.sqt1 : commentaryOverData?.sqt2)
                    const sqt2 = (sqt1 === commentaryOverData?.sqt1 ? commentaryOverData?.sqt2 : commentaryOverData?.sqt1)
                    return (<div className="accordion-item">
                        <h5 className="" data-toggle="collapse" data-target={`#bd_innings-${index}`} aria-expanded="true">
                        <div className="flag-avatar">
                            <figure>
                                <img
                                    src={
                                        TeamName(data?.teamId)?.image ||
                                      "/assets/images/flag.png"
                                    }
                                    alt="Team Image"
                                />
                            </figure>
                        </div>
                        {TeamName(data?.teamId)?.teamName || "Team Name"}
                        </h5>
                        <div id={`bd_innings-${index}`} className="collapse show">
                            <div className="acr-body">
                                <section className="pt-0">
                                    <div className="widget widget-rankings">
                                        <div className="card card-shadow">
                                            {commentaryOverData?.ov.filter(a => a.teamId == data.teamId && a.currentInnings == data.currentInnings).map((data2, index) => {
                                                const findPreviousScore = (currentIndex) => {
                                                    const overs = commentaryOverData?.ov.filter(a => a.teamId == data.teamId && a.currentInnings == data.currentInnings);
                                                    if (!overs || currentIndex < 0) return "NaN";
                      
                                                    for (let i = currentIndex+1; i < overs.length; i++) {
                                                      const prevOver = overs[i];
                                                      if (prevOver?.teamScore !== null) {
                                                        return prevOver.teamScore;
                                                      }
                                                    }
                                                    return "NaN";
                                                };
                                                return (<>
                                                    <div className="commentary-box mb-10">
                                                        <div className="commentary-header">
                                                            <h5 className="d-flex align-items-center">
                                                                Over {data2.over+1}
                                                                <PlayerImage
                                                                    width="40px"
                                                                    playerImage={getPlayerImage(sqt1, data2.bowlerId)}
                                                                    jerseyImage={data.jersey}
                                                                />
                                                                <p className="ml-20">{getPlayerName(sqt1, data2.bowlerId)}</p>
                                                            </h5>
                                                            <p>
                                                                {data2.totalRun} runs | {data2.totalWicket} wicket
                                                                <span className="ml-2">[{data2?.teamScore ? data2?.teamScore : findPreviousScore(index)}]</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="commentary-body">
                                                        <ul className="commentary-list">
                                                            {data2.ball?.sort((a, b) => parseFloat(b.commentaryBallByBallId) - parseFloat(a.commentaryBallByBallId)).filter((item)=>item.ballType != 10).map((overBallDetails, index) => {
                                                                const previousBall = data2?.ball?.sort((a, b) => b.overCount - a.overCount)[index + 1];
                                                                const nextball = data2?.ball?.sort((a, b) => b.overCount - a.overCount)[index - 1];
                                                                if (nextball && nextball.ballIsWicket && nextball.overCount === overBallDetails.overCount) {
                                                                   return null;
                                                                }
                                                                return (
                                                                    overBallDetails?.ballType !== 0 ?
                                                                        (<li key={index}>
                                                                            <div>
                                                                                <h5>{parseFloat(overBallDetails.overCount).toFixed(1)}</h5>
                                                                                <span className={getRunClass(overBallDetails.ballType, overBallDetails.ballExtraRun, overBallDetails.ballIsBoundry, overBallDetails.ballRun, overBallDetails.ballIsWicket)}>
                                                                                    {/* {getSocketFullRuns(overBallDetails)} */}
                                                                                    {overBallDetails && Object.keys(OTHER_BALLS).includes(String(overBallDetails.ballType))
                                                                                    ? (overBallDetails.ballRun > 0 
                                                                                      ? overBallDetails.ballRun + " " : "") + "" +
                                                                                    OTHER_BALLS[overBallDetails.ballType]
                                                                                    : overBallDetails.ballIsWicket 
                                                                                      ? previousBall && previousBall.overCount === overBallDetails.overCount
                                                                                        ? `${previousBall?.ballRun > 0 ? previousBall.ballRun + " " : ""}${OTHER_BALLS[previousBall.ballType] || 0} | W`
                                                                                        : "w"
                                                                                    : overBallDetails.ballType == 9 ? "RH"
                                                                                    : overBallDetails.ballType == 10 ? ""
                                                                                      : overBallDetails.ballRun}
                                                                                    </span>
                                                                            </div>
                                                                            <p>
                                                                                {getPlayerName(sqt1, data2.bowlerId)} to {getPlayerName(sqt2, overBallDetails.batStrikeId)} , {getCommentaryRun(overBallDetails.ballType, overBallDetails.ballIsBoundry, overBallDetails.ballRun, overBallDetails.ballIsWicket)}.
                                                                                <br/> {token && <h6>{convertDateUTCToLocal(overBallDetails?.createdDate,"index")}</h6>}
                                                                            </p>
                                                                            {token && 
                                                                            <table className="widget-table table table-striped no-border">
                                                                            {/* <thead>
                                                                               <tr>
                                                                                  <th scope="col">Session</th>
                                                                                  <th scope="col">Open</th>
                                                                                  <th scope="col"></th>
                                                                                </tr>
                                                                            </thead> */}
                                                                            <tbody>
                                                                            {commentaryOverData?.marketData && commentaryOverData?.marketData?.filter((item)=>item.commentaryBallByBallId === overBallDetails.commentaryBallByBallId)?.sort((a, b) => a.id - b.id)?.map((data,index)=> (
                                                                                <tr key={index}>
                                                                                  <td style={{ width: "78%"}}><h6>{data?.marketName?.toUpperCase().replace("RUNS", "")}</h6></td>
                                                                                  <td><div className="d-flex align-items-center"><><div className="no-rate"><div className="rate-font">{data?.layPrice ? data.layPrice : "0"}</div> <div className="point-font">{data?.laySize ? data.laySize : "0"}</div>  </div>
                                                                                      <div className="yes-rate"><div className="rate-font">{data?.backPrice ? data.backPrice : "0"}</div> <div className="point-font">{data?.backSize ? data.backSize : "0"}</div></div></></div></td>
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                            </table>}
                                                                        </li>)
                                                                        : null
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
    ) : (
      <div className="card card-shadow p-0">
        <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
          <strong> Match has not started yet </strong>
        </div>
      </div>
    )
    );
};

export default CommentaryDetails;