import React, { useEffect } from "react";
import MatchMainSection from "../Components/MatchMainSection";
import MatchSideBar from "../Components/MatchSideBar";
import MainLayout from "../Layouts/MainLayout";
import { useNavigate } from "react-router-dom";

const Live = () => {
  document.title = 'Home';
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);

  return (
    <MainLayout>
      <MatchMainSection />
    </MainLayout>
  );
};

export default Live;
