import React from "react";
import Skeleton from "react-loading-skeleton";

const NewsContentSkeleton = () => {
  return (
    <div className="col-md-6">
      <div class="card card-shadow p-0">
        <div class="score-card score-card-lg p-0">
          <div class="score-card-inner px-20 py-20">
            <div class="score-card-header">
              <Skeleton />
              <Skeleton height={326} width="100%" />
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsContentSkeleton;
