import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import MainLayout from "../Layouts/MainLayout";
import Skeleton from "react-loading-skeleton";
import DynamicRoutesSkeleton from "../Components/Skeleton/DynamicRoutesSkeleton";
import { useNavigate } from "react-router-dom";

const DynamicRoutes = ({ alias }) => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();

  document.title = `${pageData?.pageName}`;

  useEffect(() => {
    const fetchPagesContent = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/menuItem/getPageContent`,
          {
            alias,
          }
        );
        const pageContent = response.data?.result;
        setPageData(pageContent);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPagesContent();
  }, [alias]);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);
  
  return (
    <>
      <MainLayout>
        {loading ? (
          <DynamicRoutesSkeleton />
        ) : (
          <div class="col-lg-9">
            <section class="live-matches pt-0">
              <div class="card card-shadow">
                {pageData.pageName}
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.pageContent,
                  }}
                />
              </div>
            </section>
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default DynamicRoutes;
