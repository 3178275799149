import React from "react";
import Skeleton from "react-loading-skeleton";

const LiveScoreboardSkeleton = () => {
  return (
    <div>
      <section className="live-matches p-0">
        <div className="card card-shadow p-0">
          <div className="table-responsive">
            <table className="widget-table table table-striped table-medium no-border">
              <thead>
                <tr>
                  <th scope="col" className="table-row-heading">
                    Batsmen
                  </th>
                  <th scope="col" className="table-data">
                    r
                  </th>
                  <th scope="col" className="table-data">
                    b
                  </th>
                  <th scope="col" className="table-data" style={{ textTransform: "lowercase"}}>
                    4s
                  </th>
                  <th scope="col" className="table-data" style={{ textTransform: "lowercase"}}>
                    6s
                  </th>
                  <th scope="col" className="table-data">
                    sr
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="player">
                    <div className="d-flex align-items-center">
                      <Skeleton height={39} width={40} />
                      <Skeleton width={100} className="mx-2" />
                    </div>
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                </tr>
                <tr>
                  <td className="player">
                    <div className="d-flex align-items-center">
                      <Skeleton height={39} width={40} />
                      <Skeleton width={100} className="mx-2" />
                    </div>
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card card-shadow p-0">
          <div className="table-responsive">
            <table className="widget-table table table-striped table-medium no-border">
              <thead>
                <tr>
                  <th scope="col" className="table-row-heading">
                    Bowler
                  </th>
                  <th scope="col" className="table-data">
                    o
                  </th>
                  <th scope="col" className="table-data">
                    m
                  </th>
                  <th scope="col" className="table-data">
                    r
                  </th>
                  <th scope="col" className="table-data">
                    w
                  </th>
                  <th scope="col">econ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="player">
                    <div className="d-flex align-items-center">
                      <Skeleton height={39} width={40} />
                      <Skeleton width={100} className="mx-2" />
                    </div>
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                  <td>
                    <Skeleton width={30} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card card-shadow">
          <div className="spell-sum-box">
            <div className="recent-spell">
              <label>recent:</label>
              <ul>
                <li>
                  <Skeleton width={50} height={40} className="mx-2" />
                </li>
                <li>
                  <Skeleton width={50} height={40} className="mx-2" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LiveScoreboardSkeleton;
