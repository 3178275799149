import React from "react";

const TopHeader = () => {
  return (
    <section className="topbar" style={{ display: "none"}}>
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-8">
          {/* <Link to={"/#"} className="logo">
            <img src="/assets/images/logo.png" alt="logo" />
            </Link> */}
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default TopHeader;
