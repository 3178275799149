import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../Features/axios";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  let [searchParams] = useSearchParams();

  const verifyEmail = async (token) => {
    const payload = {
      token: token,
    };

    try {
      const response = await axiosInstance.post(
        `/api/verifyEmailToken`,
        payload
      );
      
      if (response?.data?.result) {
        setData(response?.data?.result);
      } else {
        setData({message : "Error verifying the email" })
      }
    } catch (error) {
      console.error("Request failed", error);
      setData({message : "Error verifying the email" })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    verifyEmail(token);
  }, [searchParams]);

  return (
    <>
      {loading ? (
        <h2 style={{ textAlign: "center", marginTop: "20px" }}>Loading...</h2>
      ) : (
        data && <h2 style={{ textAlign: "center", marginTop: "20px" }}>{data?.message}</h2>
      )}
    </>
  );
};

export default VerifyEmail;