import React from "react";
import Skeleton from "react-loading-skeleton";
import "./Skeleton.css";

const BannerSkeleton = () => {
  return (
    <div class="card card-shadow p-0 banner-skeleton">
      <div class="score-card score-card-lg p-0">
        <div class="score-card-inner px-20 py-20">
          <div class="score-card-header">
            <Skeleton width="100%" height={182} />
            <Skeleton className="my-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSkeleton;
