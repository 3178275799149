import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Live from "../Pages/Live";
import FullScore from "../Pages/FullScore";
import Scoreboard from "../Pages/Scoreboard";
import DynamicRoutes from "../Pages/DynamicRoutes";
import News from "../Pages/News";
import NewsDetails from "../Pages/NewsDetails";
import Scoreboard2 from "../Pages/Scoreboard2";
import BannerDetails from "../Pages/BannerDetails";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import VerifyOtp from "../Pages/VerifyOtp";
import SetPassword from "../Pages/SetPassword";
import ForgetPassword from "../Pages/ForgetPassword";
import SetNewPassword from "../Pages/SetNewPassword";
import Profile from "../Pages/Profile";
import VerifyEmail from "../Pages/VerifyEmail";
import VerifyMobile from "../Pages/VerifyMobile";

const PublicRoutes = () => {
  const [routes, setRoutes] = useState([]);

  const fetchPagesList = async () => {
    try {
      const response = await axiosInstance.post(`/admin/menuItem/getPagesList`);
      const pagesList = response.data?.result;
      setRoutes(pagesList);
    } catch (error) {
      console.error("Error fetching pages list:", error);
    }
  };

  useEffect(() => {
    fetchPagesList();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Live />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verifyOtp" element={<VerifyOtp />} />
        <Route exact path="/verifyMobile" element={<VerifyMobile />} />
        <Route exact path="/forgetPassword" element={<ForgetPassword />} />
        <Route exact path="/setPassword" element={<SetPassword />} />
        <Route exact path="/setNewPassword" element={<SetNewPassword />} />
        <Route exact path="/full-score/:id/*" element={<FullScore />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/news/:id/*" element={<NewsDetails />} />
        <Route exact path="/banner/:id/*" element={<BannerDetails />} />
        <Route exact path="/scoreboard" element={<Scoreboard />} />
        <Route exact path="/scoreboard2" element={<Scoreboard2 />} />

        {routes.map((page) => (
          <Route
            key={page.pageId}
            path={page.alias}
            element={<DynamicRoutes alias={page.alias} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default PublicRoutes;
