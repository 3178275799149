import { useState } from "react";
import MatchContent from "./MatchContent";

const NewsSideBar = () => {
  const [loading, setLoading] = useState(true)
  return (
    <div class="col-lg-3">
      <section class="live-matches pt-0">
        <MatchContent matchType={"UPCOMING"} newsContent={true} loading={loading} setLoading={setLoading} />
      </section>
    </div>
  );
};

export default NewsSideBar;
