import React from 'react'
import './PlayerImage.css'
const PlayerImage = ({ width, playerImage, jerseyImage }) => {
    const jerseyMargin = "-12px";
    return (
        <div className='imageRoot ml-3 mr-2'>
            <img src={playerImage || "/assets/images/default-player.avif"} alt="Player"
             style={{ width }} />
            {jerseyMargin && <img className="mr-top" src={jerseyImage || "/assets/images/default-jersy.png"}
                alt="Jersy" style={{ width, marginTop: jerseyMargin }} />}
        </div>
    )
}

export const ScoreboardPlayerImage = ({ width, playerImage, jerseyImage }) => {
    const jerseyMargin = "-7px";
    return (
        <div className='imageRoot ml-3 mr-2'>
            <img src={playerImage || "/assets/images/default-player.avif"} alt="Player"
             style={{ width }} />
            {jerseyMargin && <img className="mr-top" src={jerseyImage || "/assets/images/default-jersy.png"}
                alt="Jersy" style={{ width, marginTop: jerseyMargin }} />}
        </div>
    )
}

export const PlayerImageInOvers = ({ width, playerImage, jerseyImage }) => {
    const jerseyMargin = "-9px";
    return (
        <div className='imageRootToOvers'>
            <img src={playerImage || "/assets/images/default-player.avif"} alt="Player"
             style={{ width }} />
            {jerseyMargin && <img  src={jerseyImage || "/assets/images/default-jersy.png"}
                alt="Jersy" style={{ width, marginTop: jerseyMargin }} />}
        </div>
    )
}

export const PlayerImageInScoreCard = ({ width, playerImage, jerseyImage }) => {
    const jerseyMargin = "-9px";
    return (
        <div className='imageRootToScoreCard'>
            <img src={playerImage || "/assets/images/default-player.avif"} alt="Player"
             style={{ width }} />
            {jerseyMargin && <img  src={jerseyImage || "/assets/images/default-jersy.png"}
                alt="Jersy" style={{ width, marginTop: jerseyMargin }} />}
        </div>
    )
}
export default PlayerImage