import React from "react";
import { Link } from "react-router-dom";
import PlayerImage from "../Components/PlayerImage";
import LiveScoreboardSkeleton from "./Skeleton/LiveScoreboardSkeleton";
import "./LiveScoreboard.css";

const LiveScoreboard = ({ commentaryData, loading }) => {
  return (
    <>
      {loading ? (
        <LiveScoreboardSkeleton />
      ) : (
    commentaryData && (
      <div>
        <section className="live-matches p-0">
          <div className="card card-shadow p-0">
            <div className="table-responsive">
              <table className="widget-table table table-striped table-medium no-border">
                <thead className="batsmen-header">
                  <tr>
                    <th scope="col" className="table-row-heading">Batsmen</th>
                    <th scope="col" className="table-data">r</th>
                    <th scope="col" className="table-data">b</th>
                    <th scope="col" className="table-data" style={{ textTransform: "lowercase"}}>4s</th>
                    <th scope="col" className="table-data" style={{ textTransform: "lowercase"}}>6s</th>  
                    <th scope="col" className="table-data">sr</th>
                  </tr>
                </thead>
                <tbody>
                  {commentaryData?.cbt?.map((batsman) => (
                    <tr>
                      <td className="player">
                        <div className="d-flex align-items-center">
                          <PlayerImage
                            width="40px"
                            playerImage={batsman?.bati}
                            jerseyImage={
                              commentaryData?.cm?.scot === commentaryData?.cm?.t1sn
                                ?  commentaryData?.cm?.t1jr
                                :  commentaryData?.cm?.t2jr
                            }
                          />
                          <Link to={"/#"}>
                            <strong>
                              {batsman?.batn} {batsman?.os && "*"}
                            </strong>
                          </Link>
                        </div>
                        {/* <div class="flag-avatar">
                      <figure><img src="/assets/images/flag.png" /></figure> */}
                        {/* </div> */}
                      </td>
                      <td>
                        <strong>{batsman?.trun}</strong>
                      </td>
                      <td>{batsman?.tball}</td>
                      <td>{batsman?.t4}</td>
                      <td>{batsman?.t6}</td>
                      <td>{parseFloat(batsman?.str).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="widget-table table table-striped table-medium no-border">
                <thead className="batsmen-header">
                  <tr>
                    <th scope="col" className="table-row-heading">Bowler</th>
                    <th scope="col" className="table-data">o</th>
                    <th scope="col" className="table-data">m</th>
                    <th scope="col" className="table-data">r</th>
                    <th scope="col" className="table-data">w</th>
                    <th scope="col">econ</th>
                  </tr>
                </thead>
                <tbody>
                  {commentaryData?.cbl?.map((bowler) => (
                    <tr>
                      <td className="player">
                        <div className="d-flex align-items-center">
                          <PlayerImage
                            width="40px"
                            playerImage={bowler?.bli}
                            jerseyImage={
                              commentaryData?.cm?.scot === commentaryData?.cm?.t2sn
                                ?  commentaryData?.cm?.t1jr
                                :  commentaryData?.cm?.t2jr
                            }
                          />
                          <Link to={"/#"}>
                            <strong>{bowler?.pn}*</strong>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <strong>{parseFloat(bowler?.tov).toFixed(1)}</strong>
                      </td>
                      <td>{bowler?.mov}</td>
                      <td>{bowler?.trun}</td>
                      <td>{bowler?.twik}</td>
                      <td>{parseFloat(bowler?.eco).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card card-shadow">
            <div className="spell-sum-box">
              {/* <h5>
              Last Bat:
              <span>
                Cam Fletcher c selfert b Boult 0 (2b 0x4 0x6) SR: 0 ) |{'{'}" "{'}'}
              </span>
              FOW: 47/6 (7.4 ov)
            </h5> */}
              <div className="recent-spell">
                <label>recent:</label>
                <ul>
                  {commentaryData?.currOver?.map((value, index) => (
                    <li key={index}>
                      <span className={`${value.ballType} border-black`} style={{ borderRadius:"5px !important"}}>{value.run}</span>
                    </li>
                  ))}
                </ul>
                {/* <ul>
                <li>
                  <span>01</span>
                </li>
                <li>
                  <span className="bg-primary">04</span>
                </li>
                <li>
                  <span>02</span>
                </li>
                <li>
                  <span>02</span>
                </li>
                <li>
                  <span className="bg-danger">W</span>
                </li>
                <li>
                  <span>02</span>
                </li>
              </ul> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  )}
  </>
  );
};

export default LiveScoreboard;
