import React, { useEffect } from 'react'
import FullScoreMainSection from '../Components/FullScoreMainSection'
import FullScoreLayout  from '../Layouts/FullScoreLayout'
import { useNavigate } from 'react-router-dom'

const FullScore = () => {  
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (!token) {
  //     navigate('/login')
  //   }
  // }, []);

  return (
    <FullScoreLayout >
      <FullScoreMainSection />
    </FullScoreLayout >
  )
}

export default FullScore