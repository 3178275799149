import React from "react";
import Skeleton from "react-loading-skeleton";

const ScoreCardSkeleton = () => {
  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h5 className="d-flex align-items-center py-10">
          <Skeleton height={38} width={40} borderRadius={50} />
          <Skeleton width={100} className="mx-2" />
        </h5>
        <div className="collapse show">
          <div className="acr-body">
            <div className="card card-shadow p-0">
              <div className="table-responsive">
                <table className="widget-table table table-striped table-medium no-border">
                  <thead>
                    <tr>
                      <th scope="col">Batsmen</th>
                      <th scope="col"></th>
                      <th scope="col">r</th>
                      <th scope="col">b</th>
                      <th scope="col" style={{ textTransform: "lowercase"}}>4s</th>
                      <th scope="col" style={{ textTransform: "lowercase"}}>6s</th>
                      <th scope="col">sr</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={50} />
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={50} />
                      </td>
                    </tr>
                    <tr className="score-text-bold">
                      <td>Total Score</td>
                      <td>
                        <Skeleton width={200} />
                      </td>
                      <td className="text-left" colspan="5">
                        <Skeleton width={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="spell-sum-box px-30 py-20">
                  <h5>
                    Did not bat:
                    <Skeleton height={50} />
                  </h5>
                  <h5 className="d-sm-flex">
                    Fall of Wickets:
                    <span className="ml-05">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card card-shadow p-0">
              <div className="table-responsive">
                <table className="widget-table table table-striped table-medium no-border">
                  <thead>
                    <tr>
                      <th scope="col">Bowlers</th>
                      <th scope="col">o</th>
                      <th scope="col">m</th>
                      <th scope="col">r</th>
                      <th scope="col">w</th>
                      <th scope="col">econ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <strong>
                          <Skeleton width={40} />
                        </strong>
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h5 className="d-flex align-items-center py-10">
          <Skeleton height={38} width={40} borderRadius={50} />
          <Skeleton width={100} className="mx-2" />
        </h5>
        <div className="collapse show">
          <div className="acr-body">
            <div className="card card-shadow p-0">
              <div className="table-responsive">
                <table className="widget-table table table-striped table-medium no-border">
                  <thead>
                    <tr>
                      <th scope="col">Batsmen</th>
                      <th scope="col"></th>
                      <th scope="col">r</th>
                      <th scope="col">b</th>
                      <th scope="col" style={{ textTransform: "lowercase"}}>4s</th>
                      <th scope="col" style={{ textTransform: "lowercase"}}>6s</th>
                      <th scope="col">sr</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={50} />
                      </td>
                    </tr>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={50} />
                      </td>
                    </tr>
                    <tr className="score-text-bold">
                      <td>Total Score</td>
                      <td>
                        <Skeleton width={200} />
                      </td>
                      <td className="text-left" colspan="5">
                        <Skeleton width={100} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="spell-sum-box px-30 py-20">
                  <h5>
                    Did not bat:
                    <Skeleton height={50} />
                  </h5>
                  <h5 className="d-sm-flex">
                    Fall of Wickets:
                    <span className="ml-05">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card card-shadow p-0">
              <div className="table-responsive">
                <table className="widget-table table table-striped table-medium no-border">
                  <thead>
                    <tr>
                      <th scope="col">Bowlers</th>
                      <th scope="col">o</th>
                      <th scope="col">m</th>
                      <th scope="col">r</th>
                      <th scope="col">w</th>
                      <th scope="col">econ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="d-flex align-items-center">
                        <div className="mr-4">
                          <Skeleton width={35} height={36} />
                        </div>
                        <Skeleton width={100} />
                      </td>
                      <td>
                        <strong>
                          <Skeleton width={40} />
                        </strong>
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                      <td>
                        <Skeleton width={40} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreCardSkeleton;
