import React from "react";
import Skeleton from "react-loading-skeleton";

const FullScoreContentSkeleton = () => {
  return (
    <div class="tab-content">
      <div id="live_match" class="tab-pane fade in show active">
        <div class="card card-shadow p-0">
          <div class=" p-0">
            <div className="score-card d-md-flex p-0">
              <div className="score-card-inner flex-grow-1 px-20 py-15">
                <div className="mb-15">
                  <Skeleton width={100} />
                  <Skeleton />
                </div>
                <div className="score-card-body my-1">
                  <div className="country-info">
                    <div>
                      <div>
                        <Skeleton width={40} height={40} borderRadius={50} />
                      </div>
                      <span className="country-name">
                        <Skeleton width={40} />
                      </span>
                    </div>
                    <div className="score-update ml-10">
                      <Skeleton width={40} />
                      <Skeleton width={40} />
                    </div>
                  </div>
                  <div className="country-info flex-row-reverse">
                    <div className="ml-10">
                      <div>
                        <Skeleton width={40} height={40} borderRadius={50} />
                      </div>
                      <span className="country-name">
                        <Skeleton width={40} />
                      </span>
                    </div>
                    <div className="score-update">
                      <Skeleton width={40} />
                      <Skeleton width={40} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-aside text-left px-20 py-15">
                <Skeleton width={100} />
                <Skeleton width={100} className="mb-15" />
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScoreContentSkeleton;
