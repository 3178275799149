import { Link } from "react-router-dom";
import HotTopic from "./HotTopic";
import Newsletter from "./Newsletter";
import QuickLinks from "./QuickLinks";

const Footer = () => {
    return (
        <footer class="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="foo-widget foo-text mb-40">
                                <div class="foo-text-content">
                                    {/* <Link to={"/"} class="logo"><img src="/assets/images/logo.png" alt="Cricnotch" /></Link> */}
                                    <p>
                                        It's not like Smith needed to augment his batting arsenal, but the erstwhile captain has now. It's not like Smith needed to augment his
                                        batting arsenal, but the erstwhile captain has now. It's not like Smith needed to augment his batting arsenal, but the erstwhile.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-md-6 col-lg-3">
                            <HotTopic />
                        </div> */}
                        <div class="col-md-6">
                            <QuickLinks />
                        </div>
                        {/* <div class="col-md-6 col-lg-3">
                            <Newsletter />
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="footer-copyright">
                    <div class="row">
                        <div class="col-md-6">
                            <p><Link to={"/#"}>Themeies</Link> © 2023 &nbsp;|&nbsp; All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;