import React from "react";
import CurrentOver from "./CurrentOver";
import Batsmen from "./Batsmen";
import Bowlers from "./Bowlers";
import RunRates from "./RunRates";

const DetailScore = ({
  currentMatch,
  isOpen,
  setIsOpen,
  batsmen,
  bowlers,
  styleRef,
}) => {
  const { backgroundColor, color } = styleRef?.current;
  const customStyles = {
    backgroundColor: backgroundColor !== "#null" ? backgroundColor : "#000",
    color: color !== "#null" ? color : "#fff",
  };
  const toggleIsOpen = () => {
    if (currentMatch?.ics) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className="detail-score">
      <div
        className="small-score-details down-content"
        style={{
          display: isOpen ? "none" : "flex",
          flexFlow: "column",
          ...customStyles,
        }}
      >
        <div className="details-center w-100 d-flex">
          <CurrentOver currentMatch={currentMatch} isMobile={true} />
        </div>
        <div className="w-100 d-flex">
          <div className="w-20">
            <RunRates isOnlyCrr={true} currentMatch={currentMatch} customStyles={customStyles} />
          </div>
          <div className="runs-need w-60">
            <span className="mobile-run-need">{currentMatch.rmk || currentMatch?.win}</span>
          </div>
          <div className="w-20">
            <RunRates isOnlyRrr={true} currentMatch={currentMatch} customStyles={customStyles} />
          </div>
        </div>
        <div className="more-tab more-tab-ext" style={{ display: !currentMatch?.ics && "none" }}>
          <div className="open-more-arrow" onClick={toggleIsOpen}>
            <div className="down-arrow cursor-pointer">
              <svg
                style={{ height: "45px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="26"
                viewBox="0 0 190 26"
                fill={customStyles.backgroundColor}
              >
                <path
                  d="M0.208334 0.899998C3.00833 1.4 9.20833 2.6 14.2083 3.4C28.1083 5.9 33.9083 7.6 51.1083 13.9C73.9083 22.5 79.5833 24.7999 93 25.5C107.692 25.4999 116.508 23.5 132.708 17.2C156.308 8 166.008 4.8 177.408 2.4L189.208 -1.90735e-06H92.2083C30.8083 -1.90735e-06 -2.99167 0.399998 0.208334 0.899998Z"
                  fill={customStyles.backgroundColor}
                />
                <path
                  d="M94.4988 21C93.8378 21 93.1765 20.8465 92.6728 20.5396L79.7568 12.6825C78.7477 12.0686 78.7477 11.0742 79.7568 10.4604C80.7659 9.84654 82.4005 9.84654 83.4096 10.4604L94.4988 17.2089L105.59 10.4616C106.599 9.84777 108.234 9.84777 109.243 10.4616C110.252 11.0754 110.252 12.0699 109.243 12.6837L96.3272 20.5408C95.8227 20.8478 95.1607 21 94.4988 21Z"
                  fill={customStyles.color}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="dekstop-score-details-view">
        <div
          className={`score-details${isOpen ? " down-content" : ""}`}
          style={{
            display: isOpen ? "block" : "none",
            minHeight: currentMatch.rmk !== "" && "92px",
            ...customStyles,
          }}
        > 
          <div className="d-flex">
          <Batsmen batsmen={batsmen} currentMatch={currentMatch} />
          <div className="details-center">
            <div className="more-tab" style={{ display: !currentMatch?.ics && "none" }}>
              <div className="more-arrow" onClick={toggleIsOpen}>
                <div className="down-arrow cursor-pointer" id="details_score">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="26"
                    viewBox="0 0 190 26"
                    fill={customStyles.backgroundColor}
                  >
                    <path
                      d="M188.999 24.6C186.199 24.1 179.999 22.9 174.999 22.1C161.099 19.6 155.299 17.9 138.099 11.6C115.299 3 109.624 0.700056 96.207 0C81.5154 5.59986e-05 72.6987 2 56.4987 8.3C32.8987 17.5 23.1987 20.7 11.7987 23.1L-0.00131226 25.5L96.9987 25.5C158.399 25.5 192.199 25.1 188.999 24.6Z"
                      fill={customStyles.backgroundColor}
                    />
                    <path
                      d="M94.7082 4.5C95.3692 4.5 96.0305 4.65346 96.5342 4.96038L109.45 12.8175C110.459 13.4314 110.459 14.4258 109.45 15.0396C108.441 15.6535 106.806 15.6535 105.797 15.0396L94.7082 8.29107L83.6166 15.0384C82.6076 15.6522 80.9729 15.6522 79.9638 15.0384C78.9548 14.4246 78.9548 13.4301 79.9638 12.8163L92.8798 4.95915C93.3844 4.65223 94.0463 4.5 94.7082 4.5Z"
                      fill={customStyles.color}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="over-details">
              <CurrentOver currentMatch={currentMatch} />
              <RunRates currentMatch={currentMatch} />
            </div>
            <div className="desktop-run-need">{currentMatch.rmk || currentMatch?.win}</div>
          </div>
          <Bowlers bowlers={bowlers} currentMatch={currentMatch} />
          </div>
        </div>
      </div>

      <div className="mobile-score-details-view">
        <div
          className={`score-details${isOpen ? " down-content" : ""}`}
          style={{
            display: isOpen ? "flow-root" : "none",
            minHeight: currentMatch.rmk !== "" && "100px",
            ...customStyles,
          }}
        >
          <div className="details-center">
            <div className="more-tab" style={{ display: !currentMatch?.ics && "none" }}>
              <div className="more-arrow" onClick={toggleIsOpen}>
                <div className="down-arrow cursor-pointer" id="details_score">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="26"
                    viewBox="0 0 190 26"
                    fill={customStyles.backgroundColor}
                  >
                    <path
                      d="M188.999 24.6C186.199 24.1 179.999 22.9 174.999 22.1C161.099 19.6 155.299 17.9 138.099 11.6C115.299 3 109.624 0.700056 96.207 0C81.5154 5.59986e-05 72.6987 2 56.4987 8.3C32.8987 17.5 23.1987 20.7 11.7987 23.1L-0.00131226 25.5L96.9987 25.5C158.399 25.5 192.199 25.1 188.999 24.6Z"
                      fill={customStyles.backgroundColor}
                    />
                    <path
                      d="M94.7082 4.5C95.3692 4.5 96.0305 4.65346 96.5342 4.96038L109.45 12.8175C110.459 13.4314 110.459 14.4258 109.45 15.0396C108.441 15.6535 106.806 15.6535 105.797 15.0396L94.7082 8.29107L83.6166 15.0384C82.6076 15.6522 80.9729 15.6522 79.9638 15.0384C78.9548 14.4246 78.9548 13.4301 79.9638 12.8163L92.8798 4.95915C93.3844 4.65223 94.0463 4.5 94.7082 4.5Z"
                      fill={customStyles.color}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="over-details">
              <CurrentOver currentMatch={currentMatch} isMobile={true} />
            </div>
            <div className="w-100 d-flex">
              <div className="w-20">
                <RunRates isOnlyCrr={true} currentMatch={currentMatch} customStyles={customStyles} />
              </div>
              <div className="runs-need w-60">
                <span className="mobile-run-need">{currentMatch.rmk || currentMatch.win}</span>
              </div>
              <div className="w-20">
                <RunRates isOnlyRrr={true} currentMatch={currentMatch} customStyles={customStyles} />
              </div>
            </div>
          </div>
          <Batsmen batsmen={batsmen} currentMatch={currentMatch} />
          <Bowlers bowlers={bowlers} currentMatch={currentMatch} />
        </div>
      </div>
    </div>
  );
};

export default DetailScore;
