import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";
import { USER_DATA_KEY } from "../utilities/const";
import axiosInstance from "../Features/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "../Components/Reusables/CustomToast";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isMobileOtp, setIsMobileOtp] = useState(false);
  const [isEmailOtp, setIsEmailOtp] = useState(false);
  const [editMode, setEditMode] = useState({ email: false, mobile: false });
  const [newEmail, setNewEmail] = useState("");
  const [newMobile, setNewMobile] = useState("");

  const navigate = useNavigate();
  const fetchLoginCred = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/api/configData`);
      const credentials = response?.data?.result;
      setIsMobileOtp(credentials.find(c => c.key === 'ISSENDMOBILEOTP')?.value === 'true');
      setIsEmailOtp(credentials.find(c => c.key === 'ISSENDEMAILOTP')?.value === 'true');
    } catch (error) {
      console.error("Error fetching config data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (userData) => {
    const payload = {
      email: userData?.emailId,
    };

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `/api/clientDetailsByEmailId`,
        payload
      );
      if (response?.data?.result) {
        setUserDetails(response?.data?.result);
      }
    } catch (error) {
      console.error("Client details failed", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userDataVal = localStorage.getItem(USER_DATA_KEY);
    if (userDataVal) {
      const userData = JSON.parse(userDataVal);
      fetchUserDetails(userData);
      fetchLoginCred();
    } else {
      navigate("/login");
    }
  }, []);

  const {
    clientId,
    fullName,
    userName,
    emailId,
    mobileNo,
    isEmailVerified,
    isMobileVerified,
  } = userDetails;

  const verifyEmail = async (email) => {
    const payload = {
        email: email,
      };
  
      try {
        const response = await axiosInstance.post(`/api/verifyEmail`, payload);
        if (response?.data?.result) {
          toast.success(<CustomToast title={response?.data?.title} message={response?.data?.result || "Verification Link sent to your email."} />);
        } else {
          toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Email verification failed"}/>);
        }
      } catch (error) {
        console.error("Request failed", error);
        toast.error(<CustomToast title={error?.code} message={error?.message || "Email verification failed"}/>);
      } finally {
        setLoading(false);
      }
  }

  const verifyMobile = async (email) => {
      try {
        const payload = {
          email: email,
        };
        const response = await axiosInstance.post(`/api/verifyMobile`, payload);
        if (response?.data?.result) {
          toast.success(<CustomToast title={response?.data?.title} message={response?.data?.result || "Verification otp sent to your registered mobile number."} />);
          navigate("/verifyMobile");
        } else {
          toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Mobile verification failed"}/>);
        }
      } catch (error) {
        console.error("Request failed", error);
        toast.error(<CustomToast title={error?.code} message={error?.message || "Mobile verification failed"}/>);
      } finally {
        setLoading(false);
      }
  }
  const updateClient = async () => {
    const payload = {
      clientId: clientId,     
      fullName: fullName || null,
      email: newEmail || emailId,
      mobileNo: newMobile || mobileNo,
    };

    try {
      const response = await axiosInstance.post(`/api/updateClient`, payload);
      if (response?.data?.result) {
        // toast.success(<CustomToast title={response?.data?.title} message={response?.data?.message || "Profile updated successfully"} />);
        const userData = response?.data?.result;
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
        fetchUserDetails(userData);
        setUserDetails(userData);
        setEditMode({ email: false, mobile: false });
      } else {
        toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Failed to update profile"}/>);
      }
    } catch (error) {
      console.error("Failed to update profile", error);
      toast.error(<CustomToast title={error?.code} message={error?.message || "Failed to update profile"}/>);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MainLayout>
        <div class="col-lg-9">
          <section class="live-matches pt-0">
            <div class="card card-shadow">
              {loading ?
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                 <h2>Loading...</h2>
              </div> : <>
              <h3 className="mx-2">Welcome {fullName} !</h3>
              <div className="profile-details my-2">
                <h3>Your Profile</h3>
                {fullName && <p><strong>Full Name:</strong> {fullName}</p>}
                <p><strong>User Name:</strong> {userName}</p>
                {/* <p><strong>Email ID:</strong> {emailId} {isEmailVerified && <span className="mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#038974" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                  </svg></span>}</p>
                <p><strong>Mobile Number:</strong> {mobileNo || "Not Provided"} {isMobileVerified && <span className="mx-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#038974" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                  </svg></span>}</p> */}
                <p><strong>Email ID:</strong> {editMode.email ? (
                        <input type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                    ) : (
                      <>
                        {emailId} {isEmailVerified && <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#038974" className="bi bi-check-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                        </svg></span>}
                      </>
                )}</p>
                <p><strong>Mobile Number:</strong> {editMode.mobile ? (
                        <input type="text" value={newMobile} onChange={(e) => setNewMobile(e.target.value)} />
                    ) : (
                      <>
                        {mobileNo || "Not Provided"} {isMobileVerified && <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#038974" className="bi bi-check-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                        </svg></span>}
                      </>
                )}</p>

                {(editMode.email || editMode.mobile) && (
                  <>
                    <button className="btn btn-success" onClick={updateClient}>Save</button>
                    <button className="btn btn-danger mx-2" onClick={() => setEditMode({ email: false, mobile: false })}>Cancel</button>
                  </>
                )}

                {(!editMode.email && !editMode.mobile) && (
                    <button className="btn btn-success mr-2" onClick={() => { setEditMode({ email: true, mobile: true }); setNewEmail(emailId); setNewMobile(mobileNo); }}>Edit Profile</button>
                )}

                {(!isEmailVerified && emailId && isEmailOtp) && (
                  <button className="btn btn-secondary mr-2" onClick={() => verifyEmail(emailId)}>
                    Verify Email
                  </button>
                )}
                {(!isMobileVerified && mobileNo && isMobileOtp) && (
                  <button className="btn btn-secondary" onClick={() => verifyMobile(emailId)}>
                    Verify Mobile
                  </button>
                )}
              </div> </>
            }
            </div>
          </section>
          <ToastContainer />
        </div>
      </MainLayout>
    </>
  );
};

export default Profile;