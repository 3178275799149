import React from "react";
import Skeleton from "react-loading-skeleton";
import "./Skeleton.css";

const MatchContentSkeleton = () => {
  return (
    <div className="card card-shadow p-0 header-skeleton match-content-skeleton my-3">
      <div className="score-card score-card-lg d-md-flex p-0">
        <div className="score-card-inner flex-grow-1 px-15 py-15">
          <div className="score-card-header">
            <Skeleton />
          </div>
          <div className="score-card-body">
            <div className="country-info">
              <div>
                <Skeleton width={40} height={40} borderRadius={50} />
                <span className="country-name">
                  <Skeleton width={40} />
                </span>
              </div>
              <div className="score-update ml-10">
                <h5>
                  <Skeleton width={40} />
                </h5>
                <p className="text-muted">
                  <Skeleton width={40} />
                </p>
              </div>
            </div>
            <div className="score-update m-0 text-center">
              {/* <p className="vs-circle">VS</p> */}
              <Skeleton width={200} />
              <span>
                <Skeleton width={200} />
              </span>
            </div>
            <div className="country-info flex-row-reverse">
              <div className="ml-10">
                <Skeleton width={40} height={40} borderRadius={50} />
                <span className="country-name">
                  <Skeleton width={40} />
                </span>
              </div>
              <div className="score-update">
                <h5>
                  <Skeleton width={40} />
                </h5>
                <p className="text-muted">
                  <Skeleton width={40} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchContentSkeleton;
