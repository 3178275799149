import { useEffect, useState } from "react";
import MatchContentCard from "./MatchContentCard";

const MatchContentNav = ({ matchType, matchData, newsContent }) => {
    const [match, setMatch] = useState([])

    useEffect(() => {
        setMatch(matchData);
    }, [matchType])
    return (
        <>
            {
                match && match?.map((item) => (
                    <MatchContentCard
                        key={item.eid}
                        cardData={item}
                        mt={matchType}
                        newsContent={newsContent}
                    />
                ))
            }
        </>
    );
};

export default MatchContentNav;
