import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import FullscoreSideBar from '../Components/FullscoreSideBar'

const FullScoreLayout = ({ children }) => {
    return (
        <div>
            <Header />
            <div id="main" class="main-container">
                <div class="container">
                    <div class="row">
                        {children}
                        <FullscoreSideBar />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FullScoreLayout