import React, { useLayoutEffect ,useEffect } from "react";
import "./App.css";
import PublicRoutes from "./Route/PublicRoutes";
import axiosInstance from "./Features/axios";
import "react-loading-skeleton/dist/skeleton.css";
import { gapi } from "gapi-script";

const clientId =
  "522036169424-2l9gea0jf9v4tl26gppi13lv93pg3i5h.apps.googleusercontent.com";

function App() {
  useLayoutEffect(() => {
    const scripts = ["scripts.js"];
    scripts.forEach((link) => {
      const script = document.createElement("script");
      script.src = `/assets/js/${link}`;
      script.async = true;
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    function start () {
      gapi.client.init({
        clientId: clientId,
        score: ""
      })
    }
    gapi.load("client:auth2",start);
  }, []);

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const subscribeUserToPush = async () => {
    try {
      const swReg = await navigator.serviceWorker.ready;
      const applicationServerKey = urlBase64ToUint8Array('BKBY2yTmo0objcNQvRf62lx4reXpA_tGB0r4OaCJoNfc1eJv8Yf41UBIkYAWfH3NBAS0NNxL66vqGYnZJuPqHik');
      const subscription = await swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });
      // console.log('User is subscribed:', subscription);
      // Send subscription to the server
      if(subscription)
      {
        const userAgent = navigator.userAgent;
        let subObject={};
        subObject.deviceId='0';
        subObject.userId=0;
        subObject.userType=1;
        subObject.name =userAgent;
        subObject.sub = subscription;
        subObject.deviceType = 1;
        await axiosInstance.post(`/api/saveDevice`, subObject);
      }
      //await axiosInstance.post('/subscribe', subscription);
    } catch (error) {
      console.error('Failed to subscribe the user: ', error);
    }
  };

  const askPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        subscribeUserToPush();
      } else {
        console.log('Notification permission denied.');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(function(swReg) {
          console.log('Service Worker is registered', swReg);
        })
        .catch(function(error) {
          console.error('Service Worker Error', error);
        });

      askPermission();
    }
  }, []);

  return (
    <div className="App">
      <div id="preloader" className="provider-cls">
        <div id="status">
          <img src="/assets/images/logo-h.png" alt="logo" />
        </div>
      </div>
      {/* <Routes /> */}
      <PublicRoutes />
    </div>
  );
}

export default App;
