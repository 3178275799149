import React from 'react'

const RenderStatus = ({ display }) => {
    if (display) {
        return (<div className="status-text">
            <div className="over-title Status zoominout-text">
                {display}
            </div>
        </div>)
    } else {
        return (<div className="vs-image">
            <img src="assets/images/vs-img-dark.png" alt="dark" />
        </div>)
    }
}

export default RenderStatus