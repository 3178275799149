import React from "react";
import Skeleton from "react-loading-skeleton";

const OversSkeleton = () => {
  return (
    <div>
      <section className="live-matches p-0">
        <div className="card card-shadow p-0">
          <div className="table-responsive">
            <table className="widget-table table no-border table-striped">
              <thead>
                <tr>
                  <div className="overs-header borderRadius">
                    <th className="text-left d-flex align-items-center py-3">
                      <Skeleton width={35} height={35} borderRadius={50} />
                      <h5 className="mb-0 mx-2">
                        <Skeleton width={200} />
                      </h5>
                    </th>
                  </div>
                </tr>
              </thead>
              <tbody>
                <tr className="d-flex align-Items-center py-1">
                  <div className="d-flex align-items-center">
                    <Skeleton width={35} height={40} className="ml-2" />
                  </div>
                  <div className="py-1 width-full">
                    <div class="d-flex align-items-center justify-content-between py-3 height-0">
                      <div>
                        <td className="d-flex align-items-center">
                          <span className="mr-3 ml-1">
                            <Skeleton width={20} />
                          </span>
                          <span className="ml-1 font-sm-cm">
                            <Skeleton width={100} />
                          </span>
                        </td>
                      </div>
                      <div class="w-10 px-3">
                        <span class="text-nowrap font-weight-bold font-sm-cm">
                          <Skeleton width={100} />
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                    </div>
                  </div>
                </tr>
                <tr className="d-flex align-Items-center py-1">
                  <div className="d-flex align-items-center">
                    <Skeleton width={35} height={40} className="ml-2" />
                  </div>
                  <div className="py-1 width-full">
                    <div class="d-flex align-items-center justify-content-between py-3 height-0">
                      <div>
                        <td className="d-flex align-items-center">
                          <span className="mr-3 ml-1">
                            <Skeleton width={20} />
                          </span>
                          <span className="ml-1 font-sm-cm">
                            <Skeleton width={100} />
                          </span>
                        </td>
                      </div>
                      <div class="w-10 px-3">
                        <span class="text-nowrap font-weight-bold font-sm-cm">
                          <Skeleton width={100} />
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card card-shadow p-0">
          <div className="table-responsive">
            <table className="widget-table table no-border table-striped">
              <thead>
                <tr>
                  <div className="overs-header borderRadius">
                    <th className="text-left d-flex align-items-center py-3">
                      <Skeleton width={35} height={35} borderRadius={50} />
                      <h5 className="mb-0 mx-2">
                        <Skeleton width={200} />
                      </h5>
                    </th>
                  </div>
                </tr>
              </thead>
              <tbody>
                <tr className="d-flex align-Items-center py-1">
                  <div className="d-flex align-items-center">
                    <Skeleton width={35} height={40} className="ml-2" />
                  </div>
                  <div className="py-1 width-full">
                    <div class="d-flex align-items-center justify-content-between py-3 height-0">
                      <div>
                        <td className="d-flex align-items-center">
                          <span className="mr-3 ml-1">
                            <Skeleton width={20} />
                          </span>
                          <span className="ml-1 font-sm-cm">
                            <Skeleton width={100} />
                          </span>
                        </td>
                      </div>
                      <div class="w-10 px-3">
                        <span class="text-nowrap font-weight-bold font-sm-cm">
                          <Skeleton width={100} />
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                    </div>
                  </div>
                </tr>
                <tr className="d-flex align-Items-center py-1">
                  <div className="d-flex align-items-center">
                    <Skeleton width={35} height={40} className="ml-2" />
                  </div>
                  <div className="py-1 width-full">
                    <div class="d-flex align-items-center justify-content-between py-3 height-0">
                      <div>
                        <td className="d-flex align-items-center">
                          <span className="mr-3 ml-1">
                            <Skeleton width={20} />
                          </span>
                          <span className="ml-1 font-sm-cm">
                            <Skeleton width={100} />
                          </span>
                        </td>
                      </div>
                      <div class="w-10 px-3">
                        <span class="text-nowrap font-weight-bold font-sm-cm">
                          <Skeleton width={100} />
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="py-md-2 py-1 mr-1">
                            <Skeleton width={39} height={25} />
                          </span>
                        </div>
                      </td>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OversSkeleton;
