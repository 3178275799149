import React from "react";
import Skeleton from "react-loading-skeleton";

const CommentaryOverSkeleton = () => {
  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h5>
          <Skeleton width={200} />
        </h5>
        <div className="collapse show">
          <div className="acr-body">
            <section className="pt-0">
              <div className="widget widget-rankings">
                <div className="card card-shadow">
                  <div className="commentary-box mb-10">
                    <div className="commentary-header">
                      <h5 className="d-flex align-items-center">
                        <Skeleton width={50} />
                        <Skeleton height={39} width={40} className="mx-2" />
                        <p className="ml-20">
                          <Skeleton width={100} />
                        </p>
                      </h5>
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                  <div className="commentary-body">
                    <ul>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                    </ul>
                  </div>
                  <div className="commentary-box mb-10">
                    <div className="commentary-header">
                      <h5 className="d-flex align-items-center">
                        <Skeleton width={50} />
                        <Skeleton height={39} width={40} className="mx-2" />
                        <p className="ml-20">
                          <Skeleton width={100} />
                        </p>
                      </h5>
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                  <div className="commentary-body">
                    <ul>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h5>
          <Skeleton width={200} />
        </h5>
        <div className="collapse show">
          <div className="acr-body">
            <section className="pt-0">
              <div className="widget widget-rankings">
                <div className="card card-shadow">
                  <div className="commentary-box mb-10">
                    <div className="commentary-header">
                      <h5 className="d-flex align-items-center">
                        <Skeleton width={50} />
                        <Skeleton height={39} width={40} className="mx-2" />
                        <p className="ml-20">
                          <Skeleton width={100} />
                        </p>
                      </h5>
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                  <div className="commentary-body">
                    <ul>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                    </ul>
                  </div>
                  <div className="commentary-box mb-10">
                    <div className="commentary-header">
                      <h5 className="d-flex align-items-center">
                        <Skeleton width={50} />
                        <Skeleton height={39} width={40} className="mx-2" />
                        <p className="ml-20">
                          <Skeleton width={100} />
                        </p>
                      </h5>
                      <p>
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                  <div className="commentary-body">
                    <ul>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                      <li className="d-flex align-items-center py-3">
                        <Skeleton width={40} />
                        <Skeleton height={37} width={50} className="mx-2" />
                        <Skeleton width={200} className="mx-2" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentaryOverSkeleton;
