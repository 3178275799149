import React from "react";

const RunRates = ({ currentMatch, customStyles, isOnlyCrr = false, isOnlyRrr = false }) => {
  return (
    <div className="run-rates w-100 pb-1">
      {!isOnlyRrr && currentMatch?.crr ? (currentMatch?.crr !== 0) && (
        <div
          className="run-rate team1CR hide"
          style={{ color: customStyles?.color }}
        >
          CR {currentMatch?.crr}
        </div>
      ) : null}
      {!isOnlyCrr && currentMatch?.rrr ?
        (currentMatch.rrr !== 0) && (
          <div className="run-rate hide" style={{ color: customStyles?.color }}>
            RRR {currentMatch?.rrr || ""}
          </div>
        ) : null}
    </div>
  );
};

export default RunRates;
