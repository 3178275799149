import React from "react";
import Skeleton from "react-loading-skeleton";

const PartnershipDetailsSkeleton = () => {
  return (
    <>
      <div className="accordion" id="accordion">
        <div className="accordion-item">
          <h5 className="d-flex align-items-center py-10">
            <Skeleton
              width={40}
              height={38}
              borderRadius={50}
            />
            <Skeleton width={200} className="mx-2"/>
          </h5>
          <div className="collapse show">
            <div className="card card-shadow p-0">
              <div className="score-card score-card-lg d-md-flex p-0">
                <div className="score-card-inner flex-grow-1 px-20 py-15">
                  <div className="score-card-body py-1">
                    <div className="country-info cards-divider">
                      <div>
                        <Skeleton height={39} width={40} className="mx-2" />
                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                    <h2>
                      <Skeleton width={100} />
                    </h2>
                    <div className="country-info flex-row-reverse cards-divider">
                      <div className="ml-10">
                        <Skeleton height={39} width={40} className="mx-2" />

                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-shadow p-0">
              <div className="score-card score-card-lg d-md-flex p-0">
                <div className="score-card-inner flex-grow-1 px-20 py-15">
                  <div className="score-card-body py-1">
                    <div className="country-info cards-divider">
                      <div>
                        <Skeleton height={39} width={40} className="mx-2" />
                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                    <h2>
                      <Skeleton width={100} />
                    </h2>
                    <div className="country-info flex-row-reverse cards-divider">
                      <div className="ml-10">
                        <Skeleton height={39} width={40} className="mx-2" />

                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h5 className="d-flex align-items-center py-10">
            <Skeleton
              width={40}
              height={38}
              borderRadius={50}
            />
            <Skeleton width={200} className="mx-2"/>
          </h5>
          <div className="collapse show">
            <div className="card card-shadow p-0">
              <div className="score-card score-card-lg d-md-flex p-0">
                <div className="score-card-inner flex-grow-1 px-20 py-15">
                  <div className="score-card-body py-1">
                    <div className="country-info cards-divider">
                      <div>
                        <Skeleton height={39} width={40} className="mx-2" />
                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                    <h2>
                      <Skeleton width={100} />
                    </h2>
                    <div className="country-info flex-row-reverse cards-divider">
                      <div className="ml-10">
                        <Skeleton height={39} width={40} className="mx-2" />

                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-shadow p-0">
              <div className="score-card score-card-lg d-md-flex p-0">
                <div className="score-card-inner flex-grow-1 px-20 py-15">
                  <div className="score-card-body py-1">
                    <div className="country-info cards-divider">
                      <div>
                        <Skeleton height={39} width={40} className="mx-2" />
                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                    <h2>
                      <Skeleton width={100} />
                    </h2>
                    <div className="country-info flex-row-reverse cards-divider">
                      <div className="ml-10">
                        <Skeleton height={39} width={40} className="mx-2" />

                        <span className="country-name">
                          <Skeleton width={50} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipDetailsSkeleton;
