import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../Features/axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import ReCAPTCHA from "react-google-recaptcha";
// import { InstagramLogin } from "@amraneze/react-instagram-login";
import { REMEMBER_ME_KEY, USER_CRED, USER_DATA_KEY } from "../utilities/const";
import { Link } from "react-router-dom";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../Components/Reusables/CustomToast";
import MainLayout from "../Layouts/MainLayout";

const clientId =
  "522036169424-2l9gea0jf9v4tl26gppi13lv93pg3i5h.apps.googleusercontent.com";

const Login = () => {
  document.title = "Login";
  const _rememberMe = JSON.parse(localStorage.getItem(REMEMBER_ME_KEY) || null);
  const [validation, setValidation] = useState({
    values: { email: "", password: "" },
    errors: {},
    touched: {},
  });
  const [rememberMe, setRememberMe] = useState(_rememberMe || false);
  // const [privacyPolicy, setPrivacyPolicy] = useState(false);
  // const [ipAddress, setIpAddress] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isFacebookLogin, setIsFacebookLogin] = useState(false);
  const [isRecaptcha, setIsRecaptcha] = useState(false);
  const [googleClientId, setGoogleClientId] = useState("");
  const [facebookAppId, setFacebookAppId] = useState("");
  const [recaptchaClientId, setRecaptchaClientId] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [loading, setLoading] = useState(true);
  const recaptchaRef = useRef();

  const navigate = useNavigate();
  const fetchLoginCred = async () => {
    try {
      const response = await axiosInstance.post(`/api/configData`);
      const credentials = response?.data?.result;
      setGoogleClientId(
        credentials.find((c) => c.key === "GoogleAppId")?.value || ""
      );
      setFacebookAppId(
        credentials.find((c) => c.key === "FacebookAppId")?.value || ""
      );
      setRecaptchaClientId(
        credentials.find((c) => c.key === "RecaptchaClientId")?.value || ""
      );
      setIsGoogleLogin(
        credentials.find((c) => c.key === "isGoogleLogin")?.value === "true"
      );
      setIsFacebookLogin(
        credentials.find((c) => c.key === "isFacebookLogin")?.value === "true"
      );
      setIsRecaptcha(
        credentials.find((c) => c.key === "isRecaptcha")?.value === "true"
      );
    } catch (error) {
      console.error("Error fetching config data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const fetchIpAddress = async () => {
    //   try {
    //     const response = await fetch("https://api.ipify.org/?format=json");
    //     const data = await response.json();
    //     setIpAddress(data.ip);
    //   } catch (error) {
    //     console.error("Error fetching IP address:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // fetchIpAddress();
    fetchLoginCred();

    const userAgent = navigator.userAgent;
    setDeviceInfo(userAgent);
  }, []);

  useEffect(() => {
    const userData = localStorage.getItem(USER_CRED); 
    const storedData = userData ? JSON.parse(localStorage.getItem(USER_CRED)) : null;
    if (storedData) {
      setValidation(prev => ({
        ...prev,
        values: {
          ...prev.values,
          email: storedData?.email,
          password: storedData?.password
        }
      }));
    }
  }, []);

  const onGoogleSuccess = async (res) => {
    // console.log("Google Login successful", res);
    const data = res.profileObj;
    const payload = {
      fullName: data.name,
      email: data.email,
      userName: data.email,
      deviceInfo: deviceInfo,
      token: res.tokenId,
      googleID: data.googleId,
      // ipAddress: ipAddress,
    };
    try {
      const response = await axiosInstance.post(`/api/signinClient`, payload);
      if (response?.data?.result?.token) {
        const { token, details } = response.data.result;
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(details));
        toast.success(
          <CustomToast
            title={response?.data?.title}
            message={response?.data?.message || " Login successfully."}
          />
        );
        // if(details?.registrationProcessStatus === 3){
        localStorage.setItem("accessToken", token);
        navigate("/");
        // } else {
        //   navigate("/setPassword");
        // }
      } else {
        toast.error(
          <CustomToast
            title={response?.data?.error?.code}
            message={response?.data?.error?.message || "Invalid User"}
          />
        );
      }
    } catch (error) {
      console.error("Login failed", error);
      toast.error(
        <CustomToast
          title={error?.code}
          message={error?.message || "Login failed"}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  const onGoogleLogoutSuccess = () => {
    // console.log("Google Logout successful");
  };

  const onGoogleFailure = (res) => {
    console.log("Google Login Failed", res);
  };

  const responseFacebook = async (response) => {
    console.log("Facebook Login successful", response);
    const data = response;
    const payload = {
      fullName: data.name,
      email: data.email,
      userName: data.email,
      deviceInfo: deviceInfo,
      token: data.accessToken,
      // ipAddress: ipAddress,
      facebookId: data.userID,
    };
    try {
      const response = await axiosInstance.post(`/api/signinClient`, payload);
      if (response?.data?.result?.token) {
        const { token, details } = response.data.result;
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(details));
        toast.success(
          <CustomToast
            title={response?.data?.title}
            message={response?.data?.message || " Login successfully."}
          />
        );
        // if(details?.registrationProcessStatus === 3){
        localStorage.setItem("accessToken", token);
        navigate("/");
        // } else {
        //   navigate("/setPassword");
        // }
      } else {
        toast.error(
          <CustomToast
            title={response?.data?.error?.code}
            message={response?.data?.error?.message || "Invalid User"}
          />
        );
      }
    } catch (error) {
      console.error("Login failed", error);
      toast.error(
        <CustomToast
          title={error?.code}
          message={error?.message || "Login failed"}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  // const responseInstagram = (response) => {
  //   console.log("Instagram Login successful", response);
  // };

  // const responseInstagramFailure = (response) => {
  //   console.log("Instagram Login Failed", response);
  // };
  // useEffect(() => {
  //   document.body.className = "bg-pattern";
  //   return function cleanup() {
  //     document.body.className = "";
  //   };
  // }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let errors = {};
    const { email, password } = validation.values;

    if (!email) errors.email = "Email is required";
    if (!password) errors.password = "Password is required";
    if (!captchaValue) errors.captchaValue = "Captcha is required";

    setValidation((prevValidation) => ({
      ...prevValidation,
      errors,
    }));

    if (Object.keys(errors).length > 0) {
      return;
    }

    // const recaptchaToken = await recaptchaRef.current.executeAsync();

    const payload = {
      email: validation.values?.email,
      userName: validation.values?.email,
      password: validation.values.password,
      deviceInfo: deviceInfo,
      captcha: captchaValue,
      // ipAddress: ipAddress,
    };
    try {
      const response = await axiosInstance.post(`/api/signinClient`, payload);
      const rememberMe = JSON.parse(
        localStorage.getItem(REMEMBER_ME_KEY) || null
      );
      if (rememberMe) {
        localStorage.setItem(USER_CRED, JSON.stringify(payload));
      } else {
        localStorage.setItem(USER_CRED, null);
      }
      if (response?.data?.result?.token) {
        const { token, details } = response?.data?.result;
        localStorage.setItem("accessToken", token);
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(details));
        toast.success(
          <CustomToast
            title={response?.data?.title}
            message={response?.data?.message || " Login successfully."}
          />
        );
        navigate("/");
      } else {
        toast.error(
          <CustomToast
            title={response?.data?.error?.code}
            message={response?.data?.error?.message || "User Not Found"}
          />
        );
      }
    } catch (error) {
      console.error("Login failed", error);
      toast.error(
        <CustomToast
          title={error?.code}
          message={error?.message || "Login failed"}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  const fogetPassword = async () => {
    let errors = {};
    const { email } = validation.values;

    if (!email) {
      errors.email = "Email is required";
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors,
      }));
      return;
    }

    const payload = {
      email: validation.values?.email,
    };
    try {
      const response = await axiosInstance.post(`/api/forgetPassword`, payload);
      if (response?.data?.result) {
        const userDetails = {
          emailId: payload.email,
          isMobileVerify: response?.data?.result?.isMobileVerify,
          isEmailVerify: response?.data?.result?.isEmailVerify,
        };
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userDetails));
        toast.success(
          <CustomToast
            title={response?.data?.title}
            message={
              response?.data?.result?.message || "Otp sent successfully."
            }
          />
        );
        navigate("/forgetPassword");
      } else {
        toast.error(
          <CustomToast
            title={response?.data?.error?.code}
            message={response?.data?.error?.message || "Invalid User"}
          />
        );
      }
    } catch (error) {
      console.error("Verification failed", error);
      toast.error(
        <CustomToast
          title={error?.code}
          message={error?.message || "Verification failed"}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...validation.errors };

    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          errors.email = "Invalid email address";
        } else {
          errors.email = "";
        }
        break;
      case "password":
        if (!value) {
          errors.password = "Password is required";
        } else {
          errors.password = "";
        }
        break;
      default:
        break;
    }

    setValidation((prevValidation) => ({
      ...prevValidation,
      values: {
        ...prevValidation.values,
        [name]: value,
      },
      errors: errors,
      touched: {
        ...prevValidation.touched,
        [name]: true,
      },
    }));
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    localStorage.setItem(REMEMBER_ME_KEY, !rememberMe);
  };

  // const handlePrivacyPolicy = () => {
  //   if (privacyPolicy === true) {
  //     setPrivacyPolicy(false);
  //   } else if (privacyPolicy === false) {
  //     setPrivacyPolicy(true);
  //   }
  // };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  return (
    <>
      <MainLayout>
        {/* <div
          className="vh-100"
          style={{ background: "linear-gradient(92deg, #49c1df61, #eff0f5)" }}
        > */}
        {/* <div className="bg-overlay"></div> */}
        <div class="col-lg-9">
          <div className="card card-shadow">
            <div className="row d-flex justify-content-center h-100">
              <div className="col-12 col-md-8 col-lg-8 col-xl-6">
                {/* <div
                className="card shadow-2-strong"
                // style={{ borderRadius: "1rem" }}
              > */}
                {/* <div className="card-body"> */}
                <h1 className="text-center login-text my-4">Sign in</h1>
                <div className="login-bottom"></div>
                <form onSubmit={handleFormSubmit} className="login-mt">
                  <div data-mdb-input-init className="form-outline mb-4">
                    {/* <label className="form-label" htmlFor="typeEmail">
                        Email
                      </label> */}
                    <input
                      type="email"
                      id="typeEmail"
                      placeholder="Email Id"
                      className="form-control form-control-lg"
                      name="email"
                      value={validation.values.email}
                      onChange={handleChange}
                    />
                    {validation.errors.email && (
                      <div className="text-danger">
                        {validation.errors.email}
                      </div>
                    )}
                  </div>
                  {/* <div data-mdb-input-init className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeUserName">
                        Username
                      </label>
                      <input
                        type="text"
                        id="typeUserName"
                        className="form-control form-control-lg"
                        name="username"
                        value={validation.values.username}
                        onChange={handleChange}
                      />
                      {validation.errors.username && (
                        <div className="text-danger">
                          {validation.errors.username}
                        </div>
                      )}
                    </div> */}

                  <div data-mdb-input-init className="form-outline mb-4">
                    {/* <label className="form-label" htmlFor="typePassword">
                        Password
                      </label> */}
                    <input
                      type="password"
                      id="typePassword"
                      className="form-control form-control-lg"
                      name="password"
                      placeholder="Password"
                      value={validation.values.password}
                      onChange={handleChange}
                    />
                    {validation.errors.password && (
                      <div className="text-danger">
                        {validation.errors.password}
                      </div>
                    )}
                  </div>

                  <div className="form-check mb-4">
                    <input
                      checked={rememberMe}
                      onClick={handleRememberMe}
                      type="checkbox"
                      className="form-check-input cursor"
                      id="customControlInline"
                      readOnly
                    />
                    <label
                      className="form-check-label ml-2"
                      htmlFor="customControlInline"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="my-4">
                  {isRecaptcha && <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchaClientId}
                    onChange={onCaptchaChange}
                  />}
                  {!captchaValue && (
                      <div className="text-danger">
                        {validation.errors.captchaValue}
                      </div>
                  )}
                  </div>

                  {/* <div className="form-check my-2">
                    <input
                      className="form-check-input mt-2"
                      type="checkbox"
                      value={privacyPolicy}
                      id="privacy-policy"
                      onChange={handlePrivacyPolicy}
                    />
                    <label
                      className="form-check-label ml-2 privacy-policy"
                      htmlFor="privacy-policy"
                    >
                      I agree to the <Link to={"#"}>Privacy Policy</Link> and{" "}
                      <Link to={"#"}>T & C</Link>
                    </label>
                  </div> */}

                  <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-primary btn-lg btn-block login-button"
                    type="submit"
                  >
                    {loading ? "Loading..." : "Login"}
                  </button>
                </form>
                <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-link btn-lg mt-2"
                    onClick={fogetPassword}
                  >
                    Forget Password ?
                </button>

                <div className="my-5 text-center">
                  <p>
                    Don't have an account ?{" "}
                    <a href="/register" className="fw-medium text-primary">
                      {" "}
                      Sign Up{" "}
                    </a>
                  </p>
                </div>
                <hr className="my-5" />
                {/* <div className="d-flex align-items-center"> */}

                <div className="my-2">
                  {isGoogleLogin && (
                    <GoogleLogin
                      clientId={googleClientId}
                      buttonText="Continue with Google"
                      onSuccess={onGoogleSuccess}
                      onFailure={onGoogleFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      className="google-button mr-2"
                    />
                  )}
                </div>
                <div className="my-2">
                  {isFacebookLogin && (
                    <FacebookLogin
                      appId={facebookAppId}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 48 48"
                        >
                          <linearGradient
                            id="awSgIinfw5_FS5MLHI~A9a_yGcWL8copNNQ_gr1"
                            x1="6.228"
                            x2="42.077"
                            y1="4.896"
                            y2="43.432"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stop-color="#0d61a9"></stop>
                            <stop offset="1" stop-color="#16528c"></stop>
                          </linearGradient>
                          <path
                            fill="url(#awSgIinfw5_FS5MLHI~A9a_yGcWL8copNNQ_gr1)"
                            d="M42,40c0,1.105-0.895,2-2,2H8c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h32	c1.105,0,2,0.895,2,2V40z"
                          ></path>
                          <path
                            d="M25,38V27h-4v-6h4v-2.138c0-5.042,2.666-7.818,7.505-7.818c1.995,0,3.077,0.14,3.598,0.208	l0.858,0.111L37,12.224L37,17h-3.635C32.237,17,32,18.378,32,19.535V21h4.723l-0.928,6H32v11H25z"
                            opacity=".05"
                          ></path>
                          <path
                            d="M25.5,37.5v-11h-4v-5h4v-2.638c0-4.788,2.422-7.318,7.005-7.318c1.971,0,3.03,0.138,3.54,0.204	l0.436,0.057l0.02,0.442V16.5h-3.135c-1.623,0-1.865,1.901-1.865,3.035V21.5h4.64l-0.773,5H31.5v11H25.5z"
                            opacity=".07"
                          ></path>
                          <path
                            fill="#fff"
                            d="M33.365,16H36v-3.754c-0.492-0.064-1.531-0.203-3.495-0.203c-4.101,0-6.505,2.08-6.505,6.819V22h-4v4	h4v11h5V26h3.938l0.618-4H31v-2.465C31,17.661,31.612,16,33.365,16z"
                          ></path>
                        </svg>
                      }
                      textButton="&nbsp;&nbsp;&nbsp;Continue with Facebook"
                    />
                  )}
                </div>
                {/* </div> */}
                {/* <GoogleLogout
                    clientId={clientId}
                    buttonText="Logout"
                    onLogoutSuccess={onGoogleLogoutSuccess}
                    /> */}
                {/* <div className="my-2">
                    <InstagramLogin
                      clientId="690366106598832"
                      buttonText="Login with Instagram"
                      onSuccess={responseInstagram}
                      onFailure={responseInstagramFailure}
                    />
                  </div> */}
                {/* <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-lg btn-block btn-primary"
                    style={{ backgroundColor: "#dd4b39" }}
                    type="button"
                  >
                    <i className="fab fa-google me-2"></i> Sign in with Google
                  </button> */}
                {/* <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-lg btn-block btn-primary mb-2"
                    style={{backgroundColor: "#3b5998"}}
                    type="button"
                  >
                    <i className="fab fa-facebook-f me-2"></i>Sign in with Facebook
                  </button> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
        {/* </div> */}
      </MainLayout>
    </>
  );
};

export default Login;
