import React from "react";
import { ScoreboardPlayerImage } from "../PlayerImage";

const Batsmen = ({ batsmen, currentMatch }) => {
  return (
    <div className="details-left overflow-scroll">
      <table>
        <tbody className="tbodybatter-bowler" id="tblBatsManScorecardlistbody">
          <tr>
            <td className="player align-left">Batter</td>
            <td className="center-align">R</td>
            <td className="center-align">B</td>
            <td className="center-align">4s</td>
            <td className="center-align">6s</td>
            <td className="center-align">SR</td>
          </tr>
          {batsmen.map((batsman, index) => {
            return (
              <React.Fragment key={index}>
                <tr className="player-details">
                  <td className="player">
                    <div className="d-flex align-items-center">
                      <ScoreboardPlayerImage
                        width="22px"
                        playerImage={batsman?.bati}
                        jerseyImage={
                          currentMatch?.scot ===
                            currentMatch?.t1sn
                            ? currentMatch?.t1jr
                            : currentMatch?.t2jr
                        }
                      />
                      {!batsman?.isp && batsman?.batn}
                      {batsman?.os && "*"}
                    </div>
                  </td>
                  <td className="center-align">{batsman?.trun}</td>
                  <td className="center-align">{batsman?.tball}</td>
                  <td className="center-align">{batsman?.t4}</td>
                  <td className="center-align">{batsman?.t6}</td>
                  <td className="center-align">{batsman?.str}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Batsmen;
